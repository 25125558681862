<template>
    <article class="glazes">
        <heading v-if="!!title" :title="title" />
        <circle-tag-list :cols="cols">
            <circle-tag class="list__item"
                v-for="glaze in selectedDoorGlazes"
                :key="glaze.id"
                :label="glaze.title"
                :is-selected="isGlazeSelected(glaze.id)"
                :is-new="glaze.is_new"
                :is-disabled="isGlazeDisabled(glaze)"
                :is-default="glaze.is_default"
                :color="glaze.color"
                is-large
                is-outline
                @click.native="selectGlaze(glaze.id)"
                data-name="Glaze"
                />
        </circle-tag-list>
    </article>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

import Heading from '@/components/Heading'
import CircleTagList from '@/components/CircleTagList'
import CircleTag from '@/components/CircleTag'

export default {
    props: {
        title: {type: String, required: false},
        cols: {type: Number, default: 1},
        isLarge: {type: Boolean, default: false}
    },
    components: {
        Heading,
        CircleTagList,
        CircleTag
    },
    computed: {
        ...mapGetters([
            'isDesktop',
            'isGlazeSelected',
            'isGlazeDisabled',
            'selectedDoorGlazes'
        ])
    },
    methods: mapActions(['selectGlaze'])
}
</script>

<style lang="scss">
.glazes {
    &__copy {
        max-width: 720px;
        text-align: left;
        @apply mb-6;
    }
    &__list {
        display: grid;
        grid-column-gap: 1rem;
    }
}
.glazes__list--col-1 { grid-template-columns: repeat(1, 1fr); }
.glazes__list--col-2 { grid-template-columns: repeat(2, 2fr); }
.glazes__list--col-3 { grid-template-columns: repeat(3, 3fr); }
</style>
