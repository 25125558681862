<template>
    <section class="next">

        <heading title="Next Steps" />

        <ul class="next__primary next__list next__list--primary">
            <li v-for="action in primary"
                class="next__item next__item--primary"
                :class="`next__item--${action.id}`"
                >
                <a
                    class="next__btn next__btn--primary"
                    :class="`next__btn--${action.id}`"
                    @click.prevent="handleAction(action.handler)"
                    >
                    <fa-icon :icon="action.icon" /> {{ action.title }}
                </a>
            </li>
        </ul>

        <p>To download a copy of this page, click "Print" and choose "Save as a PDF".</p>

        <h5>Share This Page</h5>

        <ul class="next__secondary next__list next__list--secondary">
            <li v-for="action in secondary"
                class="next__item next__item--secondary"
                :class="`next__item--${action.id}`"
                >
                <a class="next__btn next__btn--secondary"
                   :class="`next__btn--${action.id}`"
                    target="_blank"
                    @click.prevent="handleAction(action.handler)"
                    >
                    <fa-icon :icon="action.icon" />
                        <span>{{ action.title }}</span>
                </a>
            </li>
        </ul>

    </section>
</template>

<script>
import { mapActions } from 'vuex'

import Heading from '@/components/Heading'

export default {
    components: {
        Heading
    },
    data: () => ({
        currentUrl: window.location.href,
        primary: [{
            icon: ['far', 'map-marker-alt'],
            title: 'Locate a Dealer',
            handler: 'locateDealer',
            id: 'dealer'
        }, {
            icon: ['far', 'clipboard'],
            title: 'Copy URL',
            handler: 'copyUrl',
            id: 'copy'
        }, {
            icon: ['far', 'print'],
            title: 'Print Page',
            handler: 'printPage',
            id: 'print'
        }],
        secondary: [{
            icon: ['far', 'envelope'],
            title: 'Email',
            url: `mailto:?subject=Marsh Style Selector&body=REPLACE_URL`,
            handler: 'emailPage',
            id: 'email',
        }, {
            icon: ['fab', 'pinterest'],
            title: 'Pinterest',
            url: `https://www.pinterest.com/pin/create/button?url=REPLACE_URL&media=foobar.png&description=testing`,
            handler: 'pinPage',
            id: 'pinterest',
        }, {
            icon: ['fab', 'houzz'],
            title: 'Houzz',
            url: '#',
            handler: 'addToHouzz',
            id: 'houzz',
        }]
    }),
    methods: {
        handleAction(name) {
            this[name]()
        },
        ...mapActions([
            'emailPage',
            'addToHouzz',
            'copyUrl',
            'locateDealer',
            'pinPage',
            'bookmarkPage',
            'printPage',
        ]),
    },
}
</script>

<style lang="scss">
.next {
    a { cursor: pointer; }
    &__primary {
        @apply text-center;

        @screen sm {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1rem;
        }
        @screen lg {
            max-width: 700px;
        }

        li {
            @apply relative;
            @apply bg-green text-white;
            @apply py-3 px-6 mb-4 rounded-full;
            @apply text-sm font-light text-center uppercase leading-none;
            transition: all ease 250ms;

            &:hover {
                // TODO need dark green?
                @apply bg-green-light;
            }
            a {
                &::after {
                    content: "";
                    @apply absolute inset-0;
                }
                > * { pointer-events: none; }

            }
            svg { @apply mr-1; }
            span {}
        }
    }

    p {
        @apply mt-4 mb-12;
        @apply text-center;
        @screen md { @apply text-left; }
    }

    h5, &__secondary {
        @apply text-center;
        @screen lg { @apply text-left; }
    }

    h5 {
        @apply text-lg font-semibold uppercase mb-2;
    }

    &__secondary {
        li {
            @apply inline-block mr-8;
            @apply text-3xl text-center;
        }
        a {
            @apply text-green;
            transition: all ease 250ms;
            &:hover {
                @apply text-green-light;
            }
        }
        svg {}
        span {
            @apply block text-xs;
        }
    }
}
</style>
