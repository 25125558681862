<template>
    <article class="finish-type" :class="'finish-type--' + theme" :data-finish-type-id="id">
        <header class="finish-type__header" @click.prevent="$emit('toggle')"
            :class="isOpen ? 'finish-type__header--active' : 'finish-type__header--inactive'">
            <h3 class="finish-type__title">{{ title }}</h3>
        
            <span 
            class="finish-type__icon" 
            :class="isOpen ? 'finish-type__icon--open' : 'finish-type__icon--close'">
                <fa-icon :icon="['fas', 'circle']" />
            </span>
        </header>

        <section class="finish-type__body" v-show="isOpen">
            <slot />
        </section>
    </article>
</template>

<script>
export default {
    props: {
        id: {type: Number, required: true},
        title: {type: String, required: true},
        isOpen: {type: Boolean, default: false},
        theme: {type: String, default: "default"}
    }
}
</script>

<style lang="scss">
.finish-type {
    @apply border border-b-0 border-gray-dark;

    &__header {
        @apply flex justify-between items-center;
        @apply py-4 px-6;
        @apply bg-gray text-black;
        cursor: pointer;
    }
    &__title {
        @apply text-lg font-bold uppercase;
    }
    &__icon {
        @apply text-2xl cursor-pointer;
    }
    &__body {
        @apply p-6;    
        @apply border-t border-gray-dark;
    }


    &__icon {
        @apply border border-2 border-gray-dark;
        @apply rounded-full block;
        @apply flex items-center justify-center;
        @apply text-green;
        height: 24px;
        width: 24px;
        padding: 2px;
        transition: all .15s ease-in;
        font-size: 100%;

        &:hover {
            @apply border-green;
        }

        svg, i {
            transition: opacity .15s ease-in;
            @apply opacity-0;
        }

        &--open {
            @apply border-green;
            
            svg, i {
                @apply opacity-100;
            }
        }
    }

    & + & {
        border-bottom: 0;
    }
}
</style>
