<template>
    <article class="currents">
        <heading v-if="!!title" :title="title">
          <button class="currents__reset"
            @click.prevent="resetSelectionToDefaults"
            :disabled="isDefaultVariant"
            >
            <fa-icon :icon="['far', 'sync']" />
            Reset Selection
          </button>
        </heading>
        <section class="currents__list">
            <pill-tag
                class="currents__item"
                v-if="!!selectedStyle && showStyles"
                :is-large="isMobile"
                :is-interactive="isInteractive"
                @click="clearStyle"
                >Style: <b class="font-bold">{{ selectedStyle.title }}</b></pill-tag>
            <pill-tag
                class="currents__item"
                v-if="!isInteractive && showDrawer && selectedDrawer"
                :isLarge="isMobile"
                >Drawer: <b class="font-bold">{{ selectedDrawer.title }}</b></pill-tag>
            <pill-tag
                class="currents__item"
                v-if="selectedWood"
                :is-large="isMobile"
                :is-interactive="isInteractive"
                @click="clearWood"
                >Wood Species: <b class="font-bold">{{ selectedWood.title }}</b></pill-tag>
            <pill-tag
                class="currents__item"
                v-if="selectedFinish"
                :is-large="isMobile"
                :is-interactive="isInteractive"
                @click="clearFinish"
                >Finish: <b class="font-bold">{{ selectedFinish.title }}</b></pill-tag>
            <pill-tag
                class="currents__item"
                v-if="showGlaze && selectedGlaze"
                :is-large="isMobile"
                :is-interactive="isInteractive"
                >Glaze: <b class="font-bold">{{ selectedGlaze.title }}</b></pill-tag>
            <pill-tag
                class="currents__item"
                v-if="isInteractive && (selectedStyle || selectedWood || selectedFinish)"
                :isLarge="isMobile"
                :is-interactive="isInteractive"
                @click="clearAllFilters"
                is-ghost
                >Clear Filters</pill-tag>

        </section>
    </article>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

import Heading from '@/components/Heading'
import PillTag from '@/components/PillTag'

export default {
    props: {
        title: {type: String, required: false},
        isInteractive: {type: Boolean, default: false},
        showStyles: {type: Boolean, default: false},
        showGlaze: {type: Boolean, default: false}
    },
    components: {
        Heading,
        PillTag
    },
    data: () => ({
        showStyle: true,
        showWood: true,
        showFinish: true,
        showDrawer: true
    }),
    computed: mapGetters([
		'isMobile',
		'selectedStyle',
		'selectedFinish',
		'selectedWood',
		'selectedDrawer',
		'selectedGlaze',
		'defaultVariant',
		'isDefaultVariant'
	]),
	methods: mapActions([
		'clearStyle',
		'clearWood',
		'clearFinish',
		'clearAllFilters',
		'resetSelectionToDefaults'
	])
}
</script>

<style lang="scss">
.currents {
    &__list {}
    &__item {
        @screen md {
            @apply ml-4;
            &:first-of-type { @apply mr-0; }
        }
    }
}
</style>
