import * as types from '../types'
import router from '@/router'
import { GlazesResource } from '@/services'

const glazesResource = new GlazesResource()

const state = {
    list: []
}

const getters = {

    getGlaze: state => id => state.list.find(x => x.id == id) || null,

    defaultGlaze: state => state.list.find(x => x.is_default) || null,

    selectedGlaze: (state, getters, rootState) => rootState.route.params.glaze == 0
        ? null
        : getters.getGlaze(rootState.route.params.glaze),

    isGlazeSelected: (state, getters, rootState) => id => parseInt(rootState.route.params.glaze) === id,

    isGlazeDisabled: (state,getters,rootState,rootGetters) => glaze => {
        return !rootGetters.availableGlazes.includes(glaze)
    },

    selectedDoorGlazes: (state, getters) => state.list.filter(x => getters.selectedDoorVariants.filter(v => v.glaze_id == x.id).length)
}

const actions = {
    fetchGlazes({state,commit}) {
        glazesResource.getAll()
            .then(res => {
                commit(types.GLAZES_LIST_SET, {items: res.data})
            }).catch(error => {
                console.error(error)
            })
    },
    selectGlaze({rootState}, id) {
        router.replace({
            name: rootState.route.name,
            params: {glaze: id}
        })
    },
    clearGlaze({rootState}) {
        route.replace({
            name: rootState.route.name,
            params: {glaze: 0}
        })
    },
}

const mutations = {
    [types.GLAZES_LIST_SET](state, commit) {
        state.list = commit.items
    },
}

export default {state,getters,actions,mutations}
