import * as types from '../types'
import router from '@/router'
import { FinishesResource } from '@/services'

const finishesResource = new FinishesResource()

const state = {
    list: [],
	types: [],
    // sortOptions: [{
    //     id: 'new',
    //     label: 'New'
    // }, {
    //     id: 'abc',
    //     label: 'A-Z'
    // }],
    // sort: 'new',
}

const getters = {

	hasNewFinishes: state => state.list.filter(x => x.is_new).length,

    getFinish: state => id => state.list.find(x => x.id == id) || null,

	defaultFinish: state => state.list.find(x => x.is_default) || null,

    selectedFinish: (state, getters, rootState) => rootState.route.params.finish == 0
        ? null
        : getters.getFinish(rootState.route.params.finish),

    isFinishSelected: (state, getters, rootState) => id => rootState.route.params.finish == id,

    isFinishDisabled: (state, getters, rootState, rootGetters) => finish => !rootGetters.availableFinishes.includes(finish),

	filteredFinishes: (state, getters, rootState, rootGetters) => type_id => state.list.filter(x => x.type_id == type_id && rootGetters.selectedDoorFinishes.includes(x)),

    // sortedFinishes: state => state.list.sort((a,b) => {
    //     return state.sort === 'new'
    //         ? (a.is_new === b.is_new) ? 0 : a.is_new ? -1 : 1
    //         : (a.title < b.title) ? -1 : (a.title > b.title) ? 1 : 0
    // }),

    selectedDoorFinishes: (state, getters) => state.list.filter(x => getters.selectedDoorVariants.filter(v => v.finish_id == x.id).length),
    
    selectedDoorFinishTypes: (state, getters) => state.types.filter(x => getters.selectedDoorFinishes.filter(f => f.type_id == x.id).length)
}

const actions = {
    fetchFinishes({state, commit}) {
        finishesResource.getAll()
            .then(res => {
                commit(types.FINISHES_LIST_SET, {items: res.data})
            }).catch(error => {
                console.error(error)
            })
    },
    selectFinish({rootState,rootGetters}, id) {
        router.replace({
            name: rootState.route.name,
            params: {finish: id, glaze:rootGetters.defaultGlaze.id}
        })
    },
    clearFinish({rootState}) {
        router.replace({
            name: rootState.route.name,
            params: {finish: 0}
        })
    },
    setFinishesSort({commit}, sort) {
        commit(types.FINISHES_SORT_SET, {sort})
    }
}

const mutations = {
    [types.FINISHES_LIST_SET](state, commit) {
        state.list = commit.items
    },
    [types.FINISHES_SORT_SET](state, commit) {
        state.sort = commit.sort
    },
	[types.FINISHTYPES_LIST_SET](state, commit) {
		state.types = commit.items
	}
}

export default {state,getters,actions,mutations}
