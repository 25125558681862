<template>
    <article class="specs">

        <heading title="Specifications" />

        <ul class="specs__list">
            <spec-item v-if="selectedWood" title="Wood Species" :value="selectedWood.title" />
            <spec-item v-if="selectedFinish" title="Finish" :value="selectedFinish.title" />
            <spec-item v-if="selectedGlaze" title="Glaze" :value="selectedGlaze.title" />
            <spec-item v-if="selectedDoor" title="Overlay" :value="selectedDoor.overlay.title" />
            <spec-item v-if="selectedDoor" is-wide title="Door" :body="selectedDoor.description" />

            <spec-item v-if="selectedDoor" is-wide title="Drawer" :body="selectedDoorDrawerSpecs" />
        </ul>

    </article>
</template>

<script>
import { mapGetters } from 'vuex'

import Heading from '@/components/Heading'
import SpecItem from './SpecItem'

export default {
    components: {
        Heading,
        SpecItem
    },
    computed: mapGetters([
        'selectedWood',
        'selectedFinish',
        'selectedGlaze',
        'selectedDoor',
        'selectedDrawer',
		'selectedDoorDrawerSpecs'
    ])
}
</script>

<style lang="scss">
.specs {
    @apply block w-full;

    &__list {
        @apply flex flex-wrap;
    }
}
</style>
