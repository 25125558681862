<template>
    <button class="reset"
        @click.prevent="resetSelectionToDefaults"
        :disabled="isDefaultVariant"
        >
        <fa-icon :icon="['far', 'sync']" />
        Reset Selections
    </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    computed: mapGetters([
        'isDefaultVariant'
    ]),
	methods: mapActions([
		'resetSelectionToDefaults'
	])
}
</script>

<style lang="scss">
    .reset {
		white-space: nowrap;
		&:disabled { opacity: 0.25; }
	}
</style>