<template>
    <div class="preview" sticky-container>
        <aside v-sticky="isDesktop" sticky-offset="20">
            <img class="preview__img" :src="getDoorFilename(selectedDoor)" />
            <br>
            <a class="preview__link" :download="getDoorFilename(selectedDoor)" :href="getDoorFilename(selectedDoor)" target="_blank">
                <fa-icon :icon="['fal', 'arrow-to-bottom']" />
                Download Cabinet Image
            </a>
            <div class="preview__notice" v-show="selectedFinish.not_available_after">
              <fa-icon :icon="['far','exclamation-circle']" size="lg" class="mr-2"/><span> {{ selectedFinish.title }} is not available after {{ selectedFinish.not_available_after }}</span>
            </div>
            <disclaimer class="preview__copy" />
        </aside>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Disclaimer from '@/components/Disclaimer'

export default {
    components: {
        Disclaimer
    },
    computed: mapGetters([
        'isDesktop',
        'getDoorFilename',
        'selectedDoor',
        'selectedFinish'
    ]),
}
</script>

<style lang="scss">
.preview {
    @apply text-center;
    @apply w-full; // prevent layout shift while image is loading

    &__img {
        @apply inline-block mb-6 h-auto;
        max-height: 70vh;
    }
    &__link {
        @apply block mb-6;
        @apply font-light text-sm uppercase;
        @apply text-green;
        transition: all ease 250ms;

        &:hover { @apply text-green-light; }
    }
    &__copy {
        max-width: 90%;
        @screen lg { max-width: 75%; }
    }
    &__notice {
      @apply inline-flex items-center bg-redbud text-white rounded-full px-3 py-2 mb-4 pr-4;
    }
}
</style>
