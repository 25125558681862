import * as types from '@/store/types'
import router from '@/router'
import { WoodsResource } from '@/services'

const woodsResource = new WoodsResource()

const state = {
    list: []
}

const getters = {

    getWood: state => id => state.list.find(x => x.id == id) || null,

    getWoodsByFinishTypeId: state => finishTypeId => state.list.filter(x => x.finish_types.filter(ft => ft.pivot.finish_type_id == finishTypeId).length),

    defaultWood: state => state.list.find(x => x.is_default) || null,

    isWoodSelected: (state, getters, rootState) => id => rootState.route.params.wood == id,

    selectedWood: (state, getters, rootState) => rootState.route.params.wood == 0
        ? null
        : getters.getWood(rootState.route.params.wood),

    isWoodDisabled: (state, getters, rootState, rootGetters) => wood => !rootGetters.availableWoods.includes(wood),

    selectedDoorWoods: (state, getters) => state.list.filter(x => getters.selectedDoorVariants.filter(v => v.wood_id == x.id).length)
    
}

const actions = {
    fetchWoods({state, commit}) {
        woodsResource.getAll()
            .then(res => {
                commit(types.WOODS_LIST_SET, {items: res.data})
            }).catch(error => {
                console.error(error)
            })
    },
    selectWood({rootState}, id) {
        router.replace({
            name: rootState.route.name,
            params: {wood: id}
        })
    },
    clearWood({commit, dispatch, rootState}) {
        router.replace({
            name: rootState.route.name,
            params: {wood: 0}
        })
    },
}

const mutations = {
    [types.WOODS_LIST_SET](state, commit) {
        state.list = commit.items
    }
}

export default {state,getters,actions,mutations}
