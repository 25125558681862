import _ from 'lodash'
import * as types from '../types'
import { DoorsResource } from '@/services'

const doorsResource = new DoorsResource()

const state = {
    list: [],
    sortOptions: [{
        id: 'new',
        label: 'New'
    }, {
        id: 'abc',
        label: 'A-Z'
    }],
    sort: 'abc',
}

const getters = {

	hasNewDoors: state => state.list.filter(x => x.is_new).length,

    getDoor: state => id => state.list.find(x => x.id == id) || null,

    selectedDoor: (state, getters, rootState) => rootState.route.params.door == 0
        ? null
        : getters.getDoor(rootState.route.params.door),

	selectedDoorDefaultParams: (state, getters) => {
		if (!getters.selectedDoor) return {}

		return {
			drawer: getters.selectedDoor.default_variant.drawer_id,
			wood: getters.selectedDoor.default_variant.wood_id,
			finish: getters.selectedDoor.default_variant.finish_id,
			glaze: getters.selectedDoor.default_variant.glaze_id,
		}
	},

    sortDoors: state => list => list.sort((a,b) => {
        return state.sort === 'new'
            ? (a.is_new === b.is_new) ? 0 : a.is_new ? -1 : 1
            : (a.title < b.title) ? -1 : (a.title > b.title) ? 1 : 0
    }),

    getDoorFilename: (state, getters, rootState, rootGetters) => door => {

        const variants = rootGetters.availableVariants().filter(variant => variant.door_id == door.id)

        if (rootState.route.name === "Home" && getters.hasMultipleDrawers(door)) {
            const groupByDrawer = _.groupBy(variants, variant => variant.drawer_id)
            const files = []

            Object.values(groupByDrawer).forEach(drawerGroup => {
                let sample = _.sample(drawerGroup)
                let parts = [door.slug]

                parts.push(rootGetters.getDrawer(sample.drawer_id).slug)
                parts.push(rootGetters.getWood(sample.wood_id).slug)
                parts.push(rootGetters.getFinish(sample.finish_id).slug)
                // always show no glaze on grid
                parts.push(rootGetters.defaultGlaze.slug)

                files.push(process.env.VUE_APP_IMAGE_ROOT + parts.join("_") + ".jpg")
            })

            return files

        } else {
            const sample = _.sample(variants)
            const parts = [door.slug]
            parts.push(rootGetters.getDrawer(sample.drawer_id).slug)

            parts.push(rootGetters.getWood(sample.wood_id).slug)

            parts.push(rootGetters.getFinish(sample.finish_id).slug)
            // always show no glaze on grid
            parts.push(rootState.route.name === "Home"
                ? rootGetters.defaultGlaze.slug
                : rootGetters.getGlaze(rootState.route.params.glaze).slug
            )

            return process.env.VUE_APP_IMAGE_ROOT + parts.join("_") + ".jpg"
        }

        const randomVariant = _.sample(rootGetters.availableVariants().filter(variant => variant.door_id == door.id))

        if (!randomVariant)
            return null;

        const parts = [door.slug]
        parts.push(rootGetters.getDrawer(randomVariant.drawer_id).slug)
        parts.push(rootGetters.getWood(randomVariant.wood_id).slug)
        parts.push(rootGetters.getFinish(randomVariant.finish_id).slug)
        // always show no glaze on grid
        parts.push(rootState.route.name === "Home"
            ? rootGetters.defaultGlaze.slug
            : rootGetters.getGlaze(rootState.route.params.glaze).slug
        )

        return IMAGE_ROOT + parts.join("_") + ".jpg"
    },

    getDoorDrawers: (state, getters, rootState, rootGetters) => door => {
        const drawersByDoor = rootGetters.availableVariants('drawer_id').filter(variant => variant.door_id === door.id)
        return [...new Set(drawersByDoor.map(v => v.drawer_id))]
    },

    hasMultipleDrawers: (state, getters) => door => {
        return getters.getDoorDrawers(door).length > 1
    },

	selectedDoorDrawerSpecs: (state, getters, rootState, rootGetters) => {
		const doorDrawer = getters.selectedDoor.drawers.find(x => x.id == rootGetters.selectedDrawer.id)
		return doorDrawer ? doorDrawer.pivot.description : null
		return getters.selectedDoor.drawers.find(x => x.id === rootGetters.selectedDrawer.id).pivot.description ?? null
	}

}

const actions = {
    fetchDoors({state, commit}) {
        doorsResource.getAll()
            .then(res => {
                const items = res.data
                items.forEach(item => {
                    commit(types.VARIANTS_LIST_ADD, {items: item.variants})
                    delete item.variants
                })
                commit(types.DOORS_LIST_SET, {items})
            }).catch(error => {
                console.error(error)
            })
    },
    setDoorsSort({commit}, sort) {
        commit(types.DOORS_SORT_SET, {sort})
    }
}

const mutations = {
    [types.DOORS_LIST_SET](state, commit) {
        state.list = commit.items
    },
    [types.DOORS_SORT_SET](state, commit) {
        state.sort = commit.sort
    }
}

export default {state,getters,actions,mutations}
