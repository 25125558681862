<template>
    <article class="grid-item">
        <ImageSlider class="grid-item__slider"
            v-if="hasMultipleDrawers(door)"
            :slides="getDoorFilename(door)"
            :link="{name: 'DoorView', params: routeParams }"
            :door-name="door.title"
            />
        <router-link :to="{name: 'DoorView', params: routeParams }" v-else class="grid-item__link grid-item__link--image" :data-name="door.title">
            <img :src="getDoorFilename(door)" />
        </router-link>
        <h3 class="grid-item__title">
            <router-link :to="{name: 'DoorView', params: routeParams }" class="grid-item__link grid-item__link--text" :data-name="door.title">
                {{ door.title }}
            </router-link>
        </h3>
        <template v-if="hasMultipleDrawers(door)">
			<p class="grid-item__copy">Multiple Drawer<br>Fronts Available</p>
        </template>
        <pill-tag v-if="door.is_new" class="grid-item__tag">New</pill-tag>
        <div :content="door.message_long" v-tippy="{ placement : 'top', theme: 'light', arrow : true, arrowType : 'round', animation : 'fade'}" v-if="door.has_message" >
          <pill-tag v-show="door.message_type === 'alert'" :is-alert="true" class="grid-item__tag"><fa-icon :icon="['far','exclamation-circle']" class="mr-1"/>{{ door.message_short }}</pill-tag>
          <pill-tag v-show="door.message_type === 'info'" :is-info="true" class="grid-item__tag"><fa-icon :icon="['far','info-circle']" class="mr-1"/>{{ door.message_short }}</pill-tag>
        </div>
    </article>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ImageSlider from './ImageSlider'
import PillTag from '@/components/PillTag'

export default {
    props: {
        door: {type: Object, required: true},
    },
    components: {
        ImageSlider,
        PillTag
    },
    computed: {
        ...mapGetters([
            'getDoorFilename',
            'hasMultipleDrawers',
            'selectedFinish',
            'selectedWood'
        ]),
        ...mapState({
            variants: state => state.variants.list
        }),
        routeParams: function() {
            let params = {
                door: this.door.id,
                glaze: 1
            };

            if ( !this.door.default_variant ) return params;

            params.drawer = this.door.default_variant.drawer_id;

            // if nothing selected use default variant for variant
            if ( !this.selectedFinish && !this.selectedWood ) 
            {
                params.finish = this.door.default_variant.finish_id;
                params.wood = this.door.default_variant.wood_id;
            } 
            // if both wood & finish selected use those selections
            else if ( this.selectedWood && this.selectedFinish ) 
            {
                params.finish = this.selectedFinish.id;
                params.wood = this.selectedWood.id;
            } 
            // if only wood is selected use default finish for that wood
            else if ( this.selectedWood && !this.selectedFinish ) 
            {
                params.finish = this.variants
                    .find(x => x.door_id == this.door.id && x.wood_id == this.selectedWood.id && x.drawer_id == params.drawer).finish_id;
                params.wood = this.selectedWood.id;
            }
            // if only finish is selected use first variant w/ that wood
            else if ( this.selectedFinish && !this.selectedWood ) 
            {
                params.finish = this.selectedFinish.id;
                // is there a way to do this w/o search through the large variants array?
                params.wood = this.variants
                    .find(x => x.door_id == this.door.id && x.finish_id == this.selectedFinish.id && x.drawer_id == params.drawer)
                    .wood_id;
            }

            return params;
        }
    },
}
</script>

<style lang="scss">
.grid-item {
    @apply text-center;

    &__slider {}

    &__title {
        @apply font-extrabold;
        @apply mt-3;
    }

    &__copy {
        @apply font-sans;
        @apply mt-2;
        font-size: 14px;
    }

    &__tag { @apply mt-3; }
    img { pointer-events: none; }
}
</style>
