import * as types from '../types'
import router from '@/router'
import { DrawersResource } from '@/services'

const drawersResource = new DrawersResource()

const state = {
    list: []
}

const getters = {

    getDrawer: state => id => state.list.find(x => x.id == id) || null,

	defaultDrawer: state => state.list.find(x => x.is_default) || null,

    selectedDrawer: (state, getters, rootState) => rootState.route.params.drawer == 0
        ? null
        : getters.getDrawer(rootState.route.params.drawer),

    isDrawerSelected: (state, getters, rootState) => id => rootState.route.params.drawer == id,
}

const actions = {
    fetchDrawers({state,commit}) {
        drawersResource.getAll()
            .then(res => {
                commit(types.DRAWERS_LIST_SET, {items: res.data})
            }).catch(error => {
                console.error(error)
            })
    },
    selectDrawer({rootState}, id) {
        router.replace({
            name: rootState.route.name,
            params: {drawer: id}
        })
    },
    clearDrawer({rootState}) {
        router.replace({
            name: rootState.route.name,
            params: {drawer: 0}
        })
    },
}

const mutations = {
    [types.DRAWERS_LIST_SET](state, commit) {
        state.list = commit.items
    },
}

export default {state,getters,actions,mutations}
