<template>
    <footer class="tail">
        <p class="tail__copy">&copy; 2023 Marsh Cabinets. All Rights Reserved.</p>
        <nav class="tail__nav">
            <a class="tail__link"
               v-for="link in links"
               :key="link.label"
               :href="link.url"
               target="_blank">{{ link.label }}</a>
        </nav>
    </footer>
</template>

<script>
export default {
    data: () => ({
        links: [{
            label: 'Privacy Policy',
            url: 'https://www.marshcabinets.com/privacy-policy/'
        }, {
            label: 'Terms of Use',
            url: 'https://www.marshcabinets.com/terms-of-use/'
        }]
    })
}
</script>

<style lang="scss">
.tail {
    @apply flex flex-col justify-center items-center;
    @apply p-6 bg-green;
    @apply text-sm text-clay;

    @screen md {
        @apply flex-row;
    }

    &__copy {
        @apply mb-2 text-center;

        @screen md {
            @apply mb-0;
        }
    }
    &__nav {

        @screen md {
            @apply ml-2;
        }
    }
    &__link {
        @apply pl-2 ml-2;
        @apply border-l border-oatmeal-light;
        transition: all ease 250ms;

        &:first-child {
            @apply border-0 ml-0;
        }

        &:hover { @apply text-oatmeal underline; }
    }
}
</style>
