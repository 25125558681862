<template>
    <article class="woods" :class="[`woods--col-${cols}`]">
        <heading v-if="title" :title="title" />

        <circle-tag-list :cols="cols">
            <circle-tag class="list__item"
                v-for="wood in filteredWoods"
                :key="wood.id"
                :label="wood.title"
                :image="wood.image"
                :is-selected="isWoodSelected(wood.id)"
                :is-new="wood.is_new"
                :is-large="isLarge"
                :is-disabled="isWoodDisabled(wood)"
                @click.native="selectWood(wood.id)"
                data-name="Wood"
                />
        </circle-tag-list>
    </article>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

import Heading from '@/components/Heading'
import CircleTagList from '@/components/CircleTagList'
import CircleTag from '@/components/CircleTag'

export default {
    props: {
        title: {type: String, required: false},
        cols: {type: Number, default: 1},
        isLarge: {type: Boolean, default: false},
        finishType: {type: Number, default: 0, required: false}
    },
    components: {
        Heading,
        CircleTagList,
        CircleTag
    },
    computed: {
        ...mapGetters([
            'isWoodSelected',
            'isWoodDisabled',
            'getWoodsByFinishTypeId',
            'selectedDoor',
            'selectedDoorWoods'
        ]),
        ...mapState({
            woods: state => state.woods.list,
        }),
        filteredWoods() {             
            if ( this.selectedDoor ) {
                return (!this.finishType) ? this.woods : this.getWoodsByFinishTypeId(this.finishType).filter(x => this.selectedDoorWoods.includes(x));
            } else {
                return this.woods;
            }
        }
    },
    methods: mapActions(['selectWood'])
}
</script>

<style lang="scss">
.woods {
    &__list {
        display: grid;
        grid-column-gap: 1rem;
    }

}
.woods__list--col-1 { grid-template-columns: repeat(1, 1fr); }
.woods__list--col-2 { grid-template-columns: repeat(2, 2fr); }
.woods__list--col-3 { grid-template-columns: repeat(3, 3fr); }
</style>
