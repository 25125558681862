// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'

import router from './router'
import store from './store'

import { sync } from 'vuex-router-sync'
sync(store, router)

require('./fontawesome')

import VueHeadful from 'vue-headful'
Vue.component('vue-headful', VueHeadful)

import TouchEvents from 'vue2-touch-events'
Vue.use(TouchEvents)

import VueTippy, { TippyComponent } from 'vue-tippy'
Vue.use(VueTippy)
Vue.component("tippy", TippyComponent)

import VueSticky from 'vue-sticky-directive'
Vue.use(VueSticky)

import App from './components/App'

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
