// APP
export const APP_WINDOW_SET = 'APP_WINDOW_SET'
export const APP_MODAL_OPEN = 'APP_MODAL_OPEN'
export const APP_MODAL_CLOSE = 'APP_MODAL_CLOSE'

// API
export const API_FETCH_ADD = 'API_FETCH_ADD'
export const API_FETCH_SUB = 'API_FETCH_SUB'

// VARIANTS
export const VARIANTS_LIST_ADD = 'VARIANTS_LIST_ADD'

// DOORS
export const DOORS_LIST_SET = 'DOORS_LIST_SET'
export const DOORS_SORT_SET = 'DOORS_SORT_SET'

// STYLES
export const STYLES_LIST_SET = 'STYLES_LIST_SET'

// WOODS
export const WOODS_LIST_SET = 'WOODS_LIST_SET'

// FINISHES
export const FINISHES_LIST_SET = 'FINISHES_LIST_SET'
export const FINISHES_SORT_SET = 'FINISHES_SORT_SET'
export const FINISHTYPES_LIST_SET = 'FINISHTYPES_LIST_SET'

// GLAZES
export const GLAZES_LIST_SET = 'GLAZES_LIST_SET'

// DRAWERS
export const DRAWERS_LIST_SET = 'DRAWERS_LIST_SET'
