<template>
    <div id="app">
        <app-banner />

        <main id="main" role="main" v-if="!isFetching">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </main>

        <app-loading v-else />

        <app-tail />

        <template>
            <vue-headful :title="documentTitle" />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'

import AppBanner from '@/components/global/Banner'
import AppTail from '@/components/global/Tail'
import AppLoading from '@/components/Loading'

export default {
    name: 'App',
    components: {
        AppBanner,
        AppLoading,
        AppTail
    },
    computed: mapGetters(['isFetching', 'documentTitle']),
    methods: {},
	// async/await doesnt work here
    async created() {
        await store.dispatch('fetchAll')
            .then(() => {
				window.currentCabinet = store.getters.selectedDoor
					? store.getters.selectedDoor.title
					: 'grid'
            })
    },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Averia+Serif+Libre:400,700|Montserrat:100,200,300,400,500,600,700&display=swap');

@import '~tippy.js/themes/light.css';
@import '../assets/css/print';

@tailwind base;
@tailwind utilities;
@tailwind components;

html {
    //@apply bg-green;
    @apply antialiased;

    &.modal-open body { overflow: hidden; }
}
// outside of app, can't do body
#app, .modal, .tippy-popper {
    @apply font-body;
}
main {
    @apply bg-white pb-10;
}
.container {
    @apply mx-auto px-4 w-full;
    max-width: theme('screens.xl');
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .05s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
