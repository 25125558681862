import Service from './base.service'

export default class Resource extends Service
{
  constructor(path) {
    super(path)
  }

  getAll() {
    return this.http.get(this.path)
  }

  get(id) {
    return this.http.get(`${this.path}/${id}`)
  }

  create(data) {
    return this.http.post(this.path, data)
  }

  update(id, data) {
    data = Object.assign(data, {'_method': 'PUT'})
    return this.http.put(`${this.path}/${id}`, data)
  }

  delete(id) {
    const data = {'_method': 'DELETE'}
    return this.http.delete(`${this.path}/${id}`, data)
  }
}
