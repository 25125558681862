<template>
    <header class="selection-head__wrap">
        <div class="container selection-head">
            <router-link class="selection-head__back" :to="{name: 'Home', params: {door: 0, glaze: 0, drawer: 0}}">
                <fa-icon :icon="['far', 'angle-left']" /> Choose Another Style
            </router-link>

            <nav class="selection-head__actions" v-show="isDesktop">
                <span>
                    <a href="#" class="selection-head__action selection-head__action-dealer"
                        @click.prevent="locateDealer"
                                ><fa-icon :icon="['far', 'map-marker-alt']" /> Locate a Dealer</a>
                </span>
                <span>
                    Page:
                    <a href="#"
                        class="selection-head__action selection-head__action--copy"
                        @click.prevent="copyUrl"
                        >
                        <fa-icon :icon="['far', 'clipboard']" />
                    </a>
                    <a href="#"
                        class="selection-head__action selection-head__action--print"
                        @click.prevent="printPage"
                        >
                        <fa-icon :icon="['far', 'print']" />
                    </a>
                </span>
                <span>
                    Share:
                    <a href="#"
                        class="selection-head__action selection-head__action--email"
                        @click.prevent="emailPage"
                        >
                        <fa-icon :icon="['far', 'envelope']" />
                    </a>
                    <a href="#"
                        class="selection-head__action selection-head__action--pinterest"
                        @click.prevent="pinPage"
                        >
                        <fa-icon :icon="['fab', 'pinterest']" />
                    </a>
                    <a href="#"
                        class="selection-head__action selection-head__action--houzz"
                        @click.prevent="addToHouzz"
                        >
                        <fa-icon :icon="['fab', 'houzz']" />
                    </a>
                </span>

            </nav>
        </div>
    </header>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    data: () => ({
        primaryActions: [{
            icon: ['far', 'map-marker-alt'],
            title: 'Locate a Dealer',
            handler: 'locateDealer'
        }],
        pageActions: [{
            icon: ['far', 'clipboard'],
            handler: 'copyUrl',
        }, {
            icon: ['far', 'print'],
            handler: 'printPage'
        }],
        shareActions: [{
            icon: ['far', 'envelope'],
            handler: 'emailPage',
        }, {
            icon: ['fab', 'pinterest'],
            handler: 'pinPage'
        }, {
            icon: ['fab', 'houzz'],
            handler: 'addToHouzz'
        }]
    }),
    computed: mapGetters(['isDesktop']),
    methods: {
        handleAction(name) {
            this[name]()
        },
        ...mapActions([
            'emailPage',
            'locateDealer',
            'copyUrl',
            'printPage',
            'pinPage',
            'addToHouzz',
        ])
    },
}
</script>

<style lang="scss">
.selection-head {
    @apply flex justify-center items-center;

    @screen md { @apply justify-between; }

    &__wrap {
        @apply bg-clay;
        @apply pt-4 pb-4 mb-8;
        @apply flex justify-center items-center;

        @screen md {
            @apply justify-between;
        }
    }

    &__back {
        @apply text-lg text-green font-semibold uppercase;
    }

    &__actions {
        @apply text-green text-sm font-light uppercase;

        span {
            @apply border-r border-green;
            @apply mr-4 pr-4;

            &:last-of-type { @apply border-0 pr-0 mr-0; }
        }
        a { > * { pointer-events: none; } }
        svg {
            @apply mx-1;
        }
    }
}
</style>
