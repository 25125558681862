<template>
    <div :class="bem()" :content="longMessage"
         v-tippy="{ placement : 'top', theme: 'light', arrow : true, arrowType : 'round', animation : 'fade'}" @show="tippyShow">
        <span :class="bem('img')" :style="{backgroundColor: color || '#fff'}">
            <img v-if="image" :src="image" />
        </span>

        <span :class="bem('label')" >{{ label }}</span>

        <div :class="bem('pill')">
            <pill-tag v-show="isNew">New</pill-tag>
            <pill-tag v-show="hasMessage && !excludeWood" :is-alert="(messageType === 'alert')" :is-info="(messageType === 'info')">
              <fa-icon :icon="['far','exclamation-circle']" class="mr-1" v-show="messageType === 'alert'" />
              <fa-icon :icon="['far','info-circle']" class="mr-1" v-show="messageType === 'info'" />
              {{ shortMessage }}
            </pill-tag>
        </div>
    </div>
</template>

<script>
import PillTag from '@/components/PillTag'
import {mapGetters} from "vuex";

export default {
    props: {
        label: {type: String, required: true},
        color: {type: String, required: false},
        image: {required: false},
        isSelected: {type: Boolean, default: false},
        isNew: {type: Boolean, required: false},
        hasMessage: {type: Boolean, required: false, default: false},
        shortMessage: {type: String, required: false},
        longMessage: {type: String, required: false},
        messageType: {type: String, required: false},
        messageExclusions: {type:Object,required:false},
        isLarge: {type: Boolean, required: false},
        isDisabled: {type: Boolean, default: false},
        isOutline: {type: Boolean, default: false},
        isDefault: {type: Boolean, default: false}
    },
    components: { PillTag },
    computed: {
      ...mapGetters([
        'selectedWood',
      ]),
      excludeWood: function(){
        if(this.messageExclusions !== null && this.selectedWood != null && this.messageExclusions.hasOwnProperty('wood')){
          return this.messageExclusions.wood.includes(this.selectedWood.id);
        }
      }
    },
    methods: {
        tippyShow(){
          return this.hasMessage
        },
        bem(el = null) {
            const base = el ? `circle-tag__${el}` : 'circle-tag'
            return {
                [base]: true,
                [`${base}--selected`]: this.isSelected,
                [`${base}--large`]: this.isLarge,
                [`${base}--disabled`]: this.isDisabled,
                [`${base}--outline`]: this.isOutline,
                [`${base}--default`]: this.isDefault,
            }
        },
        getClasses() {
            return {
                'circle-tag': true,
                'circle-tag--selected': this.isSelected,
                'circle-tag--large': this.isLarge,
                'circle-tag--disabled': this.isDisabled,
                'circle-tag--outline': this.isOutline,
                'circle-tag--default': this.isDefault
            }
        }
    }
}
</script>

<style scoped lang="scss">
.circle-tag {
    > * { pointer-events: none; }
    @apply flex items-center justify-between;
    @apply p-1 rounded-full mb-3;
    @apply text-left cursor-pointer leading-tight;
    min-width: 200px;
    transition: all ease 250ms;
    display: grid;
    grid-template-columns: 2.5rem 2fr 100px;

    &--large {
        grid-template-columns: 4rem 2fr 60px 2rem;
    }
    &--default {}
    &--outline {}
    &--selected,
    &:hover {
        @apply bg-clay-light;
    }

    &--disabled {
        opacity: 0.1;
        pointer-events: none;
    }

    &__img {
        @apply relative inline-block align-middle;
        @apply w-8 h-8 mr-3;
        @apply rounded-full border border-white;
        overflow: hidden;

        &--large { @apply w-12 h-12; }
		&--xlarge { @apply w-24 h-24; }
        &--outline {
            &::before, &::after {
                content: "";
                @apply absolute rounded-full;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
            &::before {
                @apply border-4 border-solid border-white;
                width: 96%;
                height: 96%;
            }
            &::after {
                @apply bg-white;
                width: 50%;
                height: 50%;
            }
        }
        &--default {
            border-width: 1px;
            @apply border-black;
            &::before, &::after {
                content: "";
                @apply absolute w-full bg-black;
                width: 65%;
                height: 1px;
                top: 50%;
                left: 50%;
                transform-origin: center;
                border: 0;
            }
            &::before { transform: translate(-50%, -50%) rotate(45deg); }
            &::after { transform: translate(-50%, -50%) rotate(-45deg); }
        }
    }

    &__label {
        @apply mr-2;
    }

    &__pill {
        @apply pr-2 flex;
    }
    .pill {
        @apply ml-auto;
        justify-self: flex-end;
    }
}
</style>
