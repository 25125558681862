import axios from 'axios'
import store from '@/store'

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
	withCredentials: false,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
})

instance.interceptors.request.use(request => {
    store.commit('API_FETCH_ADD')
    return request

}, error => {
    console.error(error)
    return Promise.reject(error)
})

instance.interceptors.response.use(response => {
    store.commit('API_FETCH_SUB')
    return response

}, error => {
    store.commit('API_FETCH_SUB')
    console.error(error)
    console.error(error.response)

    return Promise.reject(error)
})

export default class Service {
  constructor(path) {
    this.http = instance
    this.path = path
  }
}
