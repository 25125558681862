import * as types from '../types'
import _ from 'lodash'
import router from '@/router'

const state = {
    list: []
}

const getters = {

    randomVariant: (state, getters) => _.sample(getters.availableVariants()),

    availableVariants: (state, getters, rootState, rootGetters) => (except = null) => {
        const requirements = {
            door_id: rootGetters.selectedDoor ? rootGetters.selectedDoor.id : null,
            drawer_id: rootGetters.selectedDrawer ? rootGetters.selectedDrawer.id : null,
            wood_id: rootGetters.selectedWood ? rootGetters.selectedWood.id : null,
            finish_id: rootGetters.selectedFinish ? rootGetters.selectedFinish.id : null,
            glaze_id: rootGetters.selectGlaze ? rootGetters.selectedGlaze.id : null,
        }

        delete requirements[except]

        return state.list.filter(variant => {
            let matched = true; // assume all
            for (var key in requirements) {
                if (requirements[key] && variant[key] != requirements[key]) {
                    matched = false;
                    break;
                }
            }
            return matched;
        })
    },

    availableDoors: (state, getters, rootState, rootGetters) => {
        const available = _.uniqBy(getters.availableVariants(), 'door_id').map(variant => variant.door_id)
            const doors = rootState.doors.list.filter(door => {
                if (rootGetters.selectedStyle) {
                    return door.style_ids.includes(rootState.route.params.style) && available.includes(door.id)
                }
                return available.includes(door.id)
            })
        return rootGetters.sortDoors(doors)
    },

    availableDrawers: (state, getters, rootState, rootGetters) => {
        const available = _.uniqBy(getters.availableVariants('drawer_id'), 'drawer_id').map(variant => variant.drawer_id)
        return rootState.drawers.list.filter(drawer => available.includes(drawer.id))
    },

    availableWoods: (state, getters, rootState, rootGetters) => {
        const available = _.uniqBy(getters.availableVariants('wood_id'), 'wood_id').map(variant => variant.wood_id)
        return rootState.woods.list.filter(wood => available.includes(wood.id))
    },

    availableFinishes: (state, getters, rootState, rootGetters) => {
        const available = _.uniqBy(getters.availableVariants('finish_id'), 'finish_id').map(variant => variant.finish_id)
        return rootState.finishes.list.filter(finish => available.includes(finish.id))
    },

    availableGlazes: (state, getters, rootState, rootGetters) => {
        const available = _.uniqBy(getters.availableVariants('glaze_id'), 'glaze_id').map(variant => variant.glaze_id)
        return rootState.glazes.list.filter(glaze => available.includes(glaze.id))
    },

	// TODO: remove this and refactor
    defaultVariant: (state, getters, rootState, rootGetters) => {
        let finish_id = getters.availableFinishes.includes(rootGetters.defaultFinish)
			? rootGetters.defaultFinish.id
			: _.sortBy(getters.availableFinishes, 'is_default')[0].id
        let drawer_id = getters.availableDrawers.includes(rootGetters.defaultDrawer)
			? rootGetters.defaultDrawer.id
			: _.sortBy(getters.availableDrawers, 'is_default')[0].id
        let wood_id = getters.availableWoods.includes(rootGetters.defaultWood)
			? rootGetters.defaultWood.id
			: _.sortBy(getters.availableWoods, 'is_default')[0].id

        return {
            finish: finish_id,
            drawer: drawer_id,
            wood: wood_id,
			glaze: rootGetters.defaultGlaze.id
        }
    },

	initialVariant: (state, getters, rootState, rootGetters) => {
		if (rootState.route.name == 'Home') return null

		// load the doors default params if we haven't filtered any already
		if (rootState.route.params.wood == 0 && rootState.route.params.finish == 0) {
			return rootGetters.selectedDoorDefaultParams
		}

		// always filter default glaze
		const available = rootGetters.availableVariants().filter(x => x.glaze_id == rootGetters.defaultGlaze.id)
		let filterFn = x => true
        
		if (rootState.route.params.wood == 0 && parseInt(rootState.route.params.finish) > 0) {
            // if finish selected but not wood; filter variants by default wood
			filterFn = x => x.wood_id == rootGetters.defaultWood.id
		} else if (parseInt(rootState.route.params.wood) > 0 && rootState.route.params.finish == 0) {
            // if wood selected but no finish; filter by default finish
			filterFn = x => x.finish_id == rootGetters.defaultFinish.id
		}

		// use the filterFn or just get the first available
		let found = _.sample(available.filter(filterFn)) ?? _.sample(available)

		return {
			drawer: found.drawer_id,
			wood: found.wood_id,
			finish: found.finish_id,
			glaze: found.glaze_id
		}
	},

	isDefaultVariant: (state, getters, rootState, rootGetters) => {
		if (rootState.route.name == 'Home') return false

		return (
			rootGetters.selectedDrawer.id == rootGetters.selectedDoor.default_variant.drawer_id
			&&
			rootGetters.selectedWood.id == rootGetters.selectedDoor.default_variant.wood_id
			&&
			rootGetters.selectedFinish.id == rootGetters.selectedDoor.default_variant.finish_id
			&&
			rootGetters.selectedGlaze.id == rootGetters.selectedDoor.default_variant.glaze_id
		)
	},

    selectedDoorVariants: (state, getters, rootState, rootGetters) => state.list.filter(x => x.door_id == getters.selectedDoor.id)
}

const actions = {
	resetSelectionToDefaults: ({rootGetters}) => {
		router.replace({params: rootGetters.selectedDoorDefaultParams})
	}
}

const mutations = {
    [types.VARIANTS_LIST_ADD] (state, commit) {
        commit.items.forEach(item => {
            state.list.push(item)
        })
    }
}

export default {state,getters,actions,mutations}
