<template>
  <div class="home">

    <section class="home__filters">
      <filter-mobile v-show="isMobile" />
      <filter-desktop v-show="!isMobile" />
    </section>

    <grid-header />
    <grid-list />


  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState
} from 'vuex'
import store from '@/store'

import FilterMobile from '@/components/filter/Mobile'
import FilterDesktop from '@/components/filter/Desktop'

import GridHeader from '@/components/grid/Header'
import GridList from '@/components/grid/List'

export default {
  components: {
    FilterMobile,
    FilterDesktop,
    GridHeader,
    GridList,
  },
  computed: mapGetters(['isMobile']),
    mounted() {
        window.currentCabinet = "grid"
    }
}
</script>

<style scoped lang="scss">
.home {
    &__head {
      @apply bg-oatmeal;
      @apply px-8 pb-6;
      @screen lg {
        @apply px-0;
      }
    }
    &__copy {
      @apply max-w-xl mx-auto;
      @apply text-center leading-normal;
      @apply font-body text-green;
    }
    &__filters {
      @apply bg-clay py-6;
      @apply flex justify-center items-center;
    }
}
</style>
