<template>
    <div class="filters">
        <dropdown-tippy label="Style" class="filters__item">
            <style-options />
        </dropdown-tippy>

        <dropdown-tippy label="Finish" class="filters__item">
            <finish-types title="What is Finish" :cols="3" />
        </dropdown-tippy>

        <dropdown-tippy label="Wood Species" class="filters__item">
            <wood-options />
        </dropdown-tippy>

    </div>
</template>

<script>
import StyleOptions from '@/components/options/Styles'
import FinishOptions from '@/components/options/Finishes'
import FinishTypes from './FinishTypes'
import WoodOptions from '@/components/options/Woods'
import DropdownTippy from './DropdownTippy'

export default {
    props: {},
    components: {
        FinishTypes,
        DropdownTippy,
        StyleOptions,
        FinishOptions,
        WoodOptions,
    },
}
</script>

<style lang="scss">
.filters {
    @apply flex justify-around items-center;

    &__item {
        @apply relative mx-6;
        @apply text-green text-lg font-semibold uppercase;
    }
}
</style>
