<template>
    <div class="mobile-filters">

        <modal title="Filter" name="filter" @closed="toggleAccordion('')">
            <template #trigger>
                <button class="mobile-filters__item">
                    <fa-icon :icon="['far', 'sliders-h']" /> Filters
                </button>
            </template>

            <accordion
                title="Finish"
                theme="oatmeal"
                :is-open="activeAccordion == 'finishes'"
                @toggle="toggleAccordion('finishes')"
                >
                <finish-types title="What is Finish" />
            </accordion>

            <accordion 
                title="Style"
                theme="oatmeal"
                :is-open="activeAccordion == 'styles'"
                @toggle="toggleAccordion('styles')"
                >
                <styles />
            </accordion>

            <accordion
                title="Wood Species"
                theme="oatmeal"
                :is-open="activeAccordion == 'woods'"
                @toggle="toggleAccordion('woods')"
                >
                <woods :cols="isLargeMobile ? 2 : 1" />
            </accordion>

            <template #footer>
                <button class="mobile-filters__action" @click.prevent="handleCloseModal">
                    Close Filters
                </button>
            </template>
        </modal>

        <modal title="Sort" name="sort">
            <template #trigger>
                <button class="mobile-filters__item">
                    <fa-icon :icon="['far', 'sort-amount-down']" /> Sort
                </button>
            </template>

            <sort-form
                :options="sortOptions"
                :selected="selectedSort"
                @click="setFinishesSort"
                prefix="doors"
                is-large
                />
        </modal>

    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'
import store from '@/store'

import Accordion from '@/components/Accordion'
import Modal from '@/components/Modal'
import SortForm from '@/components/SortForm'

import Styles from '@/components/options/Styles'
import Woods from '@/components/options/Woods'
import FinishTypes from './FinishTypes'

export default {
    props: {},
    components: {
        Accordion,
        Modal,
        SortForm,
        Styles,
        Woods,
        FinishTypes
    },
    data: () => ({
        activeAccordion: null
    }),
    computed: {
        ...mapGetters(['isMobile', 'isLargeMobile']),
        ...mapState({
            sortOptions: state => state.doors.sortOptions,
            selectedSort: state => state.doors.sort
        }),
    },
    methods: {
        ...mapActions(['setFinishesSort', 'setDoorsSort', 'closeModal']),
        toggleAccordion(name) {
            if (this.activeAccordion == name) {
                this.activeAccordion = null
            } else {
                this.activeAccordion = name
            }
        },
        handleCloseModal() {
            this.toggleAccordion('')
            store.dispatch('closeModal')
        },
        setFinishesSort(val) {
            store.dispatch('setDoorsSort', val)
            store.dispatch('closeModal')
        }
    }
}
</script>

<style lang="scss">
.mobile-filters {
    @apply flex justify-center items-center;

    &__item {
        @apply py-2 px-8 mx-3 rounded-full;
        @apply bg-green text-white;
        @apply text-sm font-semibold uppercase;

        @screen sm {
            @apply mx-6 text-lg;
        }

        svg { @apply mr-1; }
    }

    &__action {
        @apply block w-full py-3;
        @apply bg-green rounded-full;
        @apply text-center text-white uppercase font-semibold;

        svg { @apply mr-1; }
    }
}
</style>
