<template>
    <li class="spec" :class="{'spec--wide': isWide}">
        <h4 class="spec__title">{{ title }}</h4>

        <ul v-if="Array.isArray(value)" class="spec__values">
            <li v-for="val in value">{{ val }}</li>
        </ul>
        <p v-else class="spec__value">{{ value }}</p>

        <div class="spec__value" v-if="body" v-html="body" />

		<div class="spec__value spec__value--empty" v-if="!body && !value">
			Unavailable at this time
		</div>
    </li>
</template>

<script>
export default {
    props: {
        title: {type: String, required: true},
        value: {type: [String, Array], requred: false},
        body: {type: String, required: false},
        isWide: {type: Boolean, default: false},
    }
}
</script>

<style lang="scss">
.spec {
    @apply pr-4 mb-6;
    flex-basis: 50%;

    @screen lg {
        flex-basis: 25%;
    }

    &--wide {
        flex-basis: 100%;
        @screen lg {
            flex-basis: 50%;
        }
    }

    &__title {
        @apply text-xs font-semibold text-oatmeal-dark uppercase;
        @apply mb-1;
    }

    &__value {
		@apply w-full;
		&--empty {
			@apply text-sm italic;
		}
	}

    ul {
        @apply mt-2;

        li {
            @apply relative mb-1 pl-8;

            &::before {
                content: "";
                @apply bg-black;
                @apply absolute;
                top: .6rem;
                left: .7rem;
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
            }
        }
    }
}
</style>
