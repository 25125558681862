import * as types from '../types'
import router from '@/router'
import { buildUrlWithQuery } from '@/helpers'
import { AllService } from '@/services'
import _ from 'lodash'

const allService = new AllService()

const state = {
    modal: null,
}

const getters = {
    isMobile: state => (window.innerWidth < 769),
    isLargeMobile: state => (window.innerWidth < 769 && window.innerWidth > 500),
    isTablet: state => (window.innerWidth > 768 && window.innerWidth < 1024),
    // isDesktop: state => (window.innerWidth > 1023),
    isDesktop: state => (window.innerWidth > 768),
    isModalOpen: state => modal => (state.modal === modal),

	getIntroVimeoId: (state, getters) => getters.isMobile ? '444893428' : '444318439',

    documentTitle: (state, getters, rootState, rootGetters) => {
        const parts = []
        const prefix = []

        if (rootState.route.name === 'Home' && rootGetters.selectedStyle) {
            prefix.push(rootGetters.selectedStyle.title)
        }

        prefix.push(
            rootGetters.selectedDoor ? rootGetters.selectedDoor.title : null,
            rootGetters.selectedWood ? rootGetters.selectedWood.title : null,
            rootGetters.selectedFinish ? rootGetters.selectedFinish.title : null,
        )

        if (rootState.route.name === 'Home') {
            prefix.push('Cabinets')
        } else {
			if (rootGetters.selectedGlaze) {
				prefix.push('(' + rootGetters.selectedGlaze.title + ')')
			}
			if (rootGetters.selectedDrawer) {
				prefix.push('with a ' + rootGetters.selectedDrawer.title  + ' Drawer')
			}
        }

        parts.push(
            prefix.filter(x => !!x).join(" "),
            process.env.VUE_APP_TITLE_NAME,
            process.env.VUE_APP_TITLE_OWNER
        )

        return parts.join(process.env.VUE_APP_TITLE_SEP)
    }
}

const actions = {
    fetchAll({commit}) {
        return allService.getAll()
            .then(res => {
                const doors = res.data.doors
                doors.forEach(door => {
                    commit(types.VARIANTS_LIST_ADD, {items: door.variants})
                    delete door.variants
                })
                commit(types.DOORS_LIST_SET, {items: doors})

                commit(types.GLAZES_LIST_SET, {items: res.data.glazes})
                commit(types.STYLES_LIST_SET, {items: res.data.styles})
                commit(types.WOODS_LIST_SET, {items: res.data.woods})
                commit(types.FINISHES_LIST_SET, {items: res.data.finishes})
				commit(types.FINISHTYPES_LIST_SET, {items: res.data.finish_types})
                commit(types.DRAWERS_LIST_SET, {items: res.data.drawers})
            })
    },
    emailPage({getters,rootGetters}) {
        // const newLine = '%0d%0a'
        const newLine = '\n'

        let subj = 'View my Marsh cabinet style selection'

        let body = "I designed my ideal look using the Marsh Style Selector."
        body += newLine + newLine
        body += 'Style: ' + rootGetters.selectedDoor.title + newLine
        body += 'Wood Species: ' + rootGetters.selectedWood.title + newLine
        body += 'Finish: ' + rootGetters.selectedFinish.title + newLine
        body += 'Glaze: ' + rootGetters.selectedGlaze.title + newLine
        body += 'Overlay: ' + rootGetters.selectedDoor.overlay.title + newLine
        body += 'Drawer: ' + rootGetters.selectedDrawer.title + newLine
        body += newLine + 'To see the design for yourself, just click this link:' + newLine
        body += window.location.href
        body = encodeURI(body)

        window.open(`mailto:?subject=${subj}&body=${body}`)
    },
    copyUrl() {
        const msg = "Unable to copy URL. Please use your browser's share button or copy the URL from the browser."

        if (navigator.clipboard) {
            navigator.clipboard.writeText(window.location.href).then(() => {
                alert("Link copied!")
            }, () => {
                alert(msg)
            })
        } else if (navigator.userAgent.match(/ipad|iphone/i)) {
            try {
                const textArea = document.createElement('textArea');
                textArea.readOnly = true;
                textArea.contentEditable = true;
                textArea.value = msg;

                document.body.appendChild(textArea);
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
                document.execCommand('copy');
                document.body.removeChild(textArea);

                alert("Link copied!")
            } catch(e) {
                alert(msg)
            }
        } else {
            alert(msg)
        }
    },
    printPage() {
        window.print()
    },
    pinPage({rootGetters}) {
        const pinUrl = buildUrlWithQuery('https://www.pinterest.com/pin/create/button', {
            url: window.location.href,
            media: window.location.origin + rootGetters.getDoorFilename(rootGetters.selectedDoor),
            description: rootGetters.documentTitle
        })
        window.open(pinUrl)
    },
    addToHouzz({rootGetters}) {
        const houzzUrl = buildUrlWithQuery('https://www.houzz.com/imageClipperUpload', {
            link: window.location.href,
            hzid: 31064,
            imageUrl: window.location.origin + rootGetters.getDoorFilename(rootGetters.selectedDoor),
            ref: window.location.href,
        })
        window.open(houzzUrl)
    },
    locateDealer() {
        window.open('https://www.marshfurniture.com/find-a-dealer')
    },
    openModal({commit}, modal) {
        commit(types.APP_MODAL_OPEN, {modal})
    },
    closeModal({commit}) {
        commit(types.APP_MODAL_CLOSE)
    },
    clearAllFilters({rootState}) {
        router.replace({
            name: rootState.route.name,
            params: {
                style: 0,
                wood: 0,
                finish: 0
            }
        })
    },
}

const mutations = {
    [types.APP_MODAL_OPEN](state, commit) {
        state.modal = commit.modal
        document.documentElement.classList.add('modal-open')
    },
    [types.APP_MODAL_CLOSE](state) {
        state.modal = null
        document.documentElement.classList.remove('modal-open')
    }
}

export default {state,getters,actions,mutations}
