<template>
    <div>

        <span @click="handleOpen">
            <slot name="trigger" />
        </span>

        <article class="modal" v-show="isModalOpen(name)">

            <header class="modal__header">
                <h3
					class="modal__title"
					v-if="title"
					>{{ title }}</h3>
				<span v-else />
                <button class="modal__close" @click.prevent="handleClose">
                    <fa-icon :icon="['fal', 'times']" />
                </button>
            </header>

            <div class="modal__inner">
                <section class="modal__body">
                    <slot />
                </section>
            </div>

            <footer class="modal__footer" v-show="!!$slots.footer">
                <slot name="footer" />
            </footer>

        </article>

    </div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import store from '@/store'

export default {
    props: {
        name: {type: String, required: true},
        title: {type: String, required: false},
    },
    computed: mapGetters(['isModalOpen']),
    methods: {
		handleOpen() {
			this.$emit('opened')
			store.dispatch('openModal', this.name)
		},
        handleClose() {
            this.$emit('closed')
            store.dispatch('closeModal')
        }
    },
}
</script>

<style scoped lang="scss">
.modal {
    @apply block w-full h-full;
    @apply fixed inset-0;
    z-index: 9999;
    @apply bg-black;
	max-width: 100vw;

	@screen md {
		background: rgba(0,0,0,0.85);
	}

    &__header,
    &__footer {
        @apply flex py-3 px-4 justify-between items-center;
    }
    &__header {
        @apply bg-green text-white;

		@screen md { @apply bg-transparent; }
    }
    &__close {
        @apply block w-12 h-12 relative;
        @apply text-2xl;
        margin-right: -.65rem;
        svg { @apply center; }
    }
    &__inner {
        @apply pb-8;
        height: calc(100vh - 96px - 48px);
        overflow-y: auto;

		@screen md {
			@apply flex justify-center items-center;
		}
    }
    &__body {
        @apply bg-white;
    }
    &__footer {
        @apply bg-oatmeal;
        @apply block w-full;
        @apply absolute bottom-0;
    }
    &__title {
        @apply text-white font-semibold uppercase text-2xl;
    }
}
</style>
