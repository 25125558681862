<template>
    <p class="disclaimer">Due to variations in monitor and browser settings, product images may vary from actual finishes. We recommend contacting one of our dealers to view actual finish samples.</p>
</template>

<script>
export default {}
</script>

<style>
.disclaimer {
    @apply mx-auto px-6;
    @apply text-xs text-center;
}
</style>
