<template>
    <header class="banner">
        <div class="banner__cta">
            <a href="https://www.marshcabinets.com">
                <fa-icon :icon="['far', 'angle-left']" />
                &nbsp;
                &nbsp;
                Back to MarshCabinets.com
            </a>
        </div>
        <div class="banner__box">
            <a class="banner__home" href="https://www.marshcabinets.com" target="_blank" title="Visit Marsh Cabinets Home">
                <img class="banner__logo" src="@/assets/img/logo.svg" alt="Marsh Cabinets Logo" />
            </a>
            <h1 class="banner__title">Style Selector</h1>
            <p class="banner__copy">Discover the right cabinetry for your project, no matter what style you're designing. Get started by selecting your preferred style, wood species or finish.</p>
        </div>
    </header>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.banner {
    @apply flex flex-col justify-center items-center;
    @apply bg-oatmeal;
    background-image: url('./../../assets/img/styleselector_banner.jpg');
    background-size: cover;
    background-position: center;

    &__cta {
        @apply bg-green text-clay;
        @apply w-full p-4;
        @apply text-center text-sm font-semibold uppercase;
    }

    &__box {
        @apply relative text-center;
        @apply flex flex-col items-center justify-center;
        @apply py-12 px-16 my-20 mx-4;
        max-width: 40rem;
        > * { z-index: 1; }

        @screen md {
            @apply py-16 px-24 mx-0;
        }

        &::before,
        &::after {
            content: "";
            @apply absolute;
        }
        &::before {
            @apply border border-green-light border-solid;
            @apply w-full h-full;
            z-index: 1;
            max-height: 90%;
            max-width: 90%;
            top: 5%;
            left: 5%;

            @screen md {
                max-height: 84%;
                top: 8%;
            }
        }
        &::after {
            @apply inset-0 bg-clay;
            opacity: 0.85;
        }
    }

    &__home {}
    &__logo {
        @apply mb-4;
        width: 80px;
    }
    &__title {
        @apply font-heading font-extrabold text-4xl;
        @apply text-green text-center uppercase;
        @apply leading-tight tracking-widest;
        @apply my-6;
    }
	&__opener {
		@apply block mt-6;
		@apply text-green font-bold uppercase;
		cursor: pointer;
		svg { @apply text-xl; }
	}
	&__intro {
		@apply relative w-screen p-0;
		padding-top: 125%;

		@screen md {
			width: 81vw;
			padding-top: 51%;
		}
	}
}
</style>
