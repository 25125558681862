import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

import Home from '@/views/Home'
import DoorView from '@/views/Door'

const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
        path: '/',
        name: 'HomeBare',
        component: Home,
        redirect: {
            name: 'Home',
            params: {
                door: 0,
                style: 0,
                wood: 0,
                finish: 0,
                drawer: 0,
                glaze: 0
            }
        },
        children: [{
            path: ':door/:style/:wood/:finish/:drawer/:glaze',
            name: 'Home',
            component: Home
        }]
    }, {
        path: '/view',
        name: 'DoorViewBare',
        component: DoorView,
        redirect: {
            name: 'HomeBare',
        },
        children: [{
            path: ':door/:style/:wood/:finish/:drawer/:glaze',
            name: 'DoorView',
            component: DoorView,
            beforeEnter: (to, from, next) => {
                if (!to.params.door || to.params.door === "0" || to.params.door === 0) {
                    next('/')
                }

                next()
            }
        }]
    }],
    scrollBehavior (to, from, savedPosition) {
        // if name changes scroll to top
        return to.name == from.name ? savedPosition : {selector: '#main'}
    },
})

export default router
