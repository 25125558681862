<template>
    <article class="drawers">
        <heading v-if="!!title" :title="title" />
        <ul class="drawers__list">
            <li v-for="drawer in availableDrawers"
                :key="drawer.title"
                class="drawers__item"
                :class="{'drawers__item--selected': isDrawerSelected(drawer.id)}"
                @click="selectDrawer(drawer.id)"
                data-name="Drawer"
                >
				<span class="drawers__img"
					:class="{'drawers__img--selected': isDrawerSelected(drawer.id)}"
					>
					<img :src="drawer.image" />
				</span>
                <span
                    class="drawers__title"
                    :class="{'drawers__title--selected': isDrawerSelected(drawer.id)}"
                    >{{ drawer.title }}</span>
            </li>
        </ul>
    </article>
</template>

<script>

import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

import Heading from '@/components/Heading'

export default {
    props: {
        title: {type: String, required: false}
    },
    components: {
        Heading,
    },
    computed: {
        ...mapGetters([
            'isDesktop',
            'isDrawerSelected',
            'availableDrawers'
        ]),
        ...mapState({
            drawers: state => state.drawers.list,
        }),
    },
    methods: mapActions(['selectDrawer'])
}
</script>

<style lang="scss">
.drawers {
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @apply bg-gray border border-gray-dark rounded-full;

        @screen sm {
            @apply w-2/3;
        }

        @screen lg {
            @apply flex;
            width: fit-content;
        }
    }
    &__item {
		@apply flex flex-col items-center justify-center;
        @apply m-1 py-4;
        cursor: pointer;
        @apply opacity-50;

        &--selected {
            @apply bg-clay rounded-full;
            @apply opacity-100;
        }

        @screen lg {
            @apply flex-row px-5 py-3;
        }
    }
    &__img {
		@apply flex justify-center items-center;
		@apply mb-1 max-w-full;

		img {}

    }
    &__title {
        @apply block w-full px-4 rounded-full;
        @apply text-sm text-center;
        transition: all ease 250ms;

        @screen lg {
            @apply w-auto px-2;
        }
    }
}
</style>