<template>
    <header class="container grid-head">

        <p class="grid-head__showing">Showing {{ showingLabel }} styles</p>

        <div class="grid-head__tags" :class="{'grid-head__tags--active': isTagsActive}">
            <current-selections is-interactive show-styles />
        </div>

        <sort-form class="grid-head__sort"
                   prefix="grid"
                   v-show="isDesktop && hasNewDoors"
                   :options="sortOptions"
                   :selected="selectedSort"
                   @click="setDoorsSort"
                   />

    </header>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'
import store from '@/store'

import CurrentSelections from '@/components/selection/Current'
import SortForm from '@/components/SortForm'

export default {
    components: {
        CurrentSelections,
        SortForm,
    },
    computed: {
        ...mapGetters([
            'isDesktop',
            'availableDoors',
            'selectedStyle',
			'selectedFinish',
			'selectedWood',
			'hasNewDoors',
        ]),
        ...mapState({
            totalStyles: state => state.doors.list.length,
            sortOptions: state => state.doors.sortOptions,
            selectedSort: state => state.doors.sort
        }),
        isTagsActive() {
            return (this.selectedStyle || this.selectedWood || this.selectedFinish)
            return true;
        },
        showingLabel() {
            return (this.totalStyles === this.availableDoors.length)
                ? `all ${this.totalStyles}`
                : this.availableDoors.length
        }
    },
    methods: mapActions(['setDoorsSort']),
}
</script>

<style lang="scss">
.grid-head {
    @apply flex flex-col-reverse justify-center items-center;
    @apply mt-4 pt-4 pb-8;
    @apply text-center;

    @screen md {
        @apply flex-row justify-between items-center;
    }

    &__showing { min-width: 170px; }

    &__tags {
        @apply w-full;

        &--active {
            @apply border-b border-green;
            @apply pb-4 mb-6;
        }

        @screen md {
            @apply border-b-0 pb-0 mb-0;
            @apply mx-4;
        }
    }
}
</style>
