<template>
    <div class="styles">
        <span v-for="(style,index) in styles" :key="index"
              class="styles__item"
              :class="{'styles__item--selected': isStyleSelected(style.id)}"
              >
              <div class="styles__img" @click.prevent="selectStyle(style.id)">
                  <img :src="style.image" :alt="style.title + ' style example'" :data-name="style.title"/>
              </div>
              <h3 class="styles__title">{{ style.title }}</h3>
              <p class="styles__copy">{{ style.description }}</p>
              <button class="styles__btn" @click.prevent="selectStyle(style.id)" :data-name="style.title">
                  View Styles
              </button>
        </span>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'isStyleSelected',
            'selectedStyle'
        ]),
        ...mapState({
            styles: state => state.styles.list
        }),
    },
    methods: mapActions(['selectStyle', 'clearStyle'])
}
</script>

<style lang="scss">
.styles {
    @apply p-2;
    text-align: center;

    @screen sm {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: left;
    }
    @screen md {
        @apply flex;
    }

    &__item {
        @apply block p-4;
        @apply mb-4;

        &--selected .styles__btn {
            @apply bg-oatmeal-dark text-white;
        }
        @screen md {
            max-width: 270px;
        }
    }
    &__img {
        @apply flex justify-center items-center;
        @apply w-full mb-4 overflow-hidden;
        height: 150px;
        cursor: pointer;

        img { object-fit: cover; }
    }
    &__title {
        @apply text-green font-bold text-lg uppercase;
        @apply mb-4;
    }
    &__copy {
        @apply mb-4;
    }
    &__btn {
        @apply rounded-full py-2 px-6;
        @apply border border-oatmeal-dark;
        @apply text-oatmeal-dark uppercase;
        transition: all ease 250ms;

        &:hover  {
            @apply bg-oatmeal-dark text-white;
        }
        &:focus { outline: none; }
    }
}
</style>
