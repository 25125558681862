<template>
    <tippy
        arrow
        interactive
        placement="bottom"
        theme="light"
        animation="fade"
        maxWidth="400"
        :hide-on-click="true"
        :animate-fill="false"
        :flip="false"
        >

        <template #trigger>
            {{ label }} <fa-icon :icon="['far', 'angle-down']" />
        </template>

        <slot />

    </tippy>
</template>

<script>
export default {
    props: {
        label: {type: String, required: true}
    }
}
</script>

<style lang="scss">
.tippy-tooltip {
    @apply p-6;
}
</style>
