<template>
    <article class="finishes">
		<div class="finishes__group">
			<heading v-if="title" :title="title" />

			<circle-tag-list :cols="cols">
				<circle-tag
					v-for="finish in filteredFinishes"
					:key="finish.id"
					:label="finish.title"
					:is-selected="isFinishSelected(finish.id)"
					:is-new="finish.is_new"
          :has-message="finish.has_message"
          :short-message="finish.message_short"
          :long-message="finish.message_long"
          :message-type="finish.message_type"
          :message-exclusions="finish.message_exclusions"
					:is-large="isLarge"
					:is-disabled="isFinishDisabled(finish)"
					:show-remover="false"
					:color="finish.color"
					:is-default="finish.is_default"
					@click.native="selectFinish(finish.id)"
					data-name="Finish"
					/>
			</circle-tag-list>
		</div>
    </article>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

import Heading from '@/components/Heading'
import CircleTagList from '@/components/CircleTagList'
import CircleTag from '@/components/CircleTag'

export default {
    props: {
        title: {type: String, required: false},
        cols: {type: Number, default: 1},
        isLarge: {type: Boolean, default: false },
        finishType: {type: Number, default: 0, required: false}
    },
    components: {
        Heading,
        CircleTagList,
        CircleTag,
    },
    computed: {
        ...mapGetters([
            'isDesktop',
			'hasNewFinishes',
            'isFinishSelected',
			'selectedDoorFinishes',
            'isFinishDisabled',
            'availableFinishes',
            'selectedDoor',
            'selectedDoorFinishes'
        ]),
        ...mapState({
            finishes: state => state.finishes.list,
			finishTypes: state => state.finishes.types,
            selected: state => state.finishes.selected,
        }),
        filteredFinishes() {
            if ( this.selectedDoor ) {
                return (this.finishType > 0) ? 
                    this.selectedDoorFinishes.filter(x => x.type_id == this.finishType) :
                    this.selectedDoorFinishes;
            } else {
                return (this.finishType > 0) ? 
                    this.availableFinishes.filter(x => x.type_id == this.finishType) :
                    this.availableFinishes;
            }
        }
    },
    methods: mapActions(['selectFinish', 'setFinishesSort'])
}
</script>

<style lang="scss">
.finishes {
    @apply mb-4;

    &__header {
        &--mobile {
            border-bottom-width: 0 !important;
            margin-bottom: .5rem !important;
            .heading__title { text-align: left !important; }
        }
    }
    &__copy {
        max-width: 720px;
        text-align: left;
        @apply mb-6 text-sm;
    }
    &__sort {
        &--mobile {
            @apply border-solid border-green;
            border-top-width: 1px;
            border-bottom-width: 1px;
            @apply my-4 py-4;
        }
    }
}
</style>
