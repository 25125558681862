<template>
<div>
    <selection-header />

    <section class="container selected" v-if="selectedDoor">

        <div class="selected__heading" v-show="isMobile">
            <h1 class="selected__title">{{ selectedDoor.title }} {{ hasMultipleDrawers(selectedDoor) ? selectedDrawer.label : ''}}</h1>
            <reset-selections />
        </div>
        
        <selected-image class="selected__side" />

        <div class="selected__body">
            <div class="selected__heading" v-show="!isMobile">
                <h1 class="selected__title">{{ selectedDoor.title }} {{ hasMultipleDrawers(selectedDoor) ? selectedDrawer.label : ''}}</h1>
                <reset-selections />
            </div>
            <div class="selected__notice" v-show="selectedDoor.not_available_after">
              <fa-icon :icon="['far','exclamation-circle']" size="lg" class="mr-2"/><span> {{ selectedDoor.title }} {{ hasMultipleDrawers(selectedDoor) ? selectedDrawer.label : ''}} is not available after {{ selectedDoor.not_available_after }}</span>
            </div>
            <drawers class="selected__section selected__section--drawers"
                title="Select a Drawer Front"
                v-show="hasMultipleDrawers(selectedDoor)"
                />
            <finish-types class="selected__section selected__section--finishes"
                title="Select a Finish"
                />
            <glazes class="selected__section selected__section--glazes"
                title="Select a Glaze"
                :cols="3"
                v-if="selectedDoorGlazes.length > 1"
                />

            <specifications class="selected__section selected__section--specs" />

            <next-steps class="selected__section selected__section--next" />

        </div>
    </section>

</div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'
import store from '@/store'

import SelectionHeader from '@/components/selection/Header'
import SelectedImage from '@/components/selection/Image'
import ResetSelections from '@/components/selection/Reset'
import Drawers from '@/components/options/Drawers'
import FinishTypes from '@/components/options/FinishTypes'
import Glazes from '@/components/options/Glazes'
import Specifications from '@/components/selection/Specifications'
import NextSteps from '@/components/selection/NextSteps'

import _ from 'lodash'

export default {
    props: {},
    components: {
        Drawers,
        FinishTypes,
        Glazes,
        NextSteps,
        ResetSelections,
        SelectionHeader,
        SelectedImage,
        Specifications
    },
    computed: {
        ...mapGetters([
            'isMobile',
            'isDesktop',
            'selectedDoor',
			'selectedDrawer',
            'hasMultipleDrawers',
            'defaultVariant',
            'selectedDoorVariants',
            'selectedDoorFinishes',
            'selectedDoorWoods',
            'selectedDoorGlazes'
        ]),
        ...mapState({
            currentRoute: state => state.route,
        }),
    },
    mounted() {
        window.currentCabinet = this.$store.getters.selectedDoor ? this.$store.getters.selectedDoor.title : null;
    }
}
</script>

<style scoped lang="scss">
.selected {

    @screen lg {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-column-gap: theme('spacing.6');
    }

    &__side  {
        @apply mb-8;
    }
    &__body {}
    &__heading {
        @apply flex flex-col align-middle mb-4;

        @screen md {
            @apply flex-row justify-between align-middle;
        }
    }
    &__title {
        @apply font-heading text-5xl text-green text-center uppercase tracking-wider;

        @screen lg {
            @apply text-left;
        }
    }
    &__notice {
        @apply flex items-center bg-redbud text-white rounded-full px-3 py-2 mb-4;
    }
    &__section {
        @apply mb-12;
    }
}
</style>
