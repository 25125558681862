<template>
    <article class="accordion" :class="'accordion--' + theme">
        <header class="accordion__header" @click.prevent="$emit('toggle')"
            :class="isOpen ? 'accordion__header--active' : 'accordion__header--inactive'">
            <h3 class="accordion__title">{{ title }}</h3>
            <span class="accordion__icon">
                <fa-icon :icon="['far', isOpen ? 'angle-up' : 'angle-down']" />
            </span>
        </header>

        <section class="accordion__body" v-show="isOpen">
            <slot />
        </section>

    </article>
</template>

<script>
export default {
    props: {
        title: {type: String, required: true},
        isOpen: {type: Boolean, default: false},
        theme: {type: String, default: "default"}
    }
}
</script>

<style lang="scss">
.accordion {
    &--oatmeal .accordion {
        &__header {
            @apply bg-oatmeal border-green text-green;
        }

        &__body {
            @apply border-green;
        }
    }

    &--default {
        @apply border border-b-0 border-gray-dark;

        .accordion {
            &__header {
                @apply border-b-0 bg-gray border-gray-dark text-black;

                &--active {
                    @apply border-b;
                }
            }

            &__body {
                @apply border-b-0;
            }
        }

        &:last-of-type { @apply border-b; }
    }

    &__header {
        @apply flex justify-between items-center;
        @apply py-3 px-6;
        @apply border-b;
        cursor: pointer;
    }
    &__title {
        @apply text-lg font-bold uppercase;
    }
    &__icon {
        @apply text-2xl;
    }
    &__body {
        @apply p-6;
        @apply border-b;
    }
}
</style>
