import Service from './base.service'

export default class AllService extends Service
{
    constructor() {
        super('all')
    }

    getAll() {
        return this.http.get(this.path)
    }
}
