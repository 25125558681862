import * as types from '../types'

const state = {
  count: 0
}

const getters = {
  isFetching: state => (state.count > 0)
}

const actions = {}

const mutations = {
  [types.API_FETCH_ADD]: state => state.count++,
  [types.API_FETCH_SUB]: state => state.count--,
}

export default {state,getters,actions,mutations}
