import * as types from '../types'
import router from '@/router'
import { StylesResource } from '@/services'

const stylesResource = new StylesResource()

const state = {
    list: []
}

const getters = {

    isStyleSelected: (state, getters, rootState) => id => rootState.route.params.style == id,

    selectedStyle: (state, getters, rootState) => rootState.route.params.style == 0
        ? null
        : state.list.find(x => x.id == rootState.route.params.style)
}

const actions = {
    fetchStyles({state, commit}) {
        stylesResource.getAll()
            .then(res => {
                commit(types.STYLES_LIST_SET, {items: res.data})
            }).catch(error => {
                console.error(error)
            })
    },
    selectStyle({rootState}, id) {
        router.replace({
            name: rootState.route.name,
            params: {style: id}
        })
    },
    clearStyle({rootState}) {
        router.replace({
            name: rootState.route.name,
            params: {style: 0}
        })
    },
}

const mutations = {
    [types.STYLES_LIST_SET](state, commit) {
        state.list = commit.items
    },
}

export default {state,getters,actions,mutations}
