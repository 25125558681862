<template>
    <header class="heading"
		:class="{'heading--sml': isSmall}"
		>
        <h3 class="heading__title">{{ title }}</h3>
        <slot class="heading__actions" />
    </header>
</template>

<script>
export default {
    props: {
        title: {type: String, required: true},
		isSmall: {type: Boolean, default: false}
    }
}
</script>

<style scoped lang="scss">
.heading {
    @apply flex justify-between items-center;
    @apply border-b border-black;
    @apply pb-2 mb-5;

    &__title {
        @apply text-green font-bold;
        @apply text-lg uppercase;
        @apply block w-full;

        @screen md { @apply text-left; }
    }
    &__actions {}
	&--sml {
		@apply border-b-0 mb-0;
	}
}
</style>
