var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"pill",class:{
            'pill--interactive': this.isInteractive,
            'pill--ghost': this.isGhost,
            'pill--large': this.isLarge,
            'pill--alert': this.isAlert,
            'pill--info': this.isInfo
            },on:{"click":function($event){$event.preventDefault();return _vm.handleClick.apply(null, arguments)}}},[_vm._t("default"),(_vm.isInteractive)?_c('span',{staticClass:"pill__action"},[_c('fa-icon',{attrs:{"icon":['fal', 'times']}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }