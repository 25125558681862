<template>
    <article class="finish-types">
        <heading
            v-if="!!title"
            class="finish-types__header"
            :title="title"
            >
        </heading>
        <p class="finish-types__copy">We use the term finish to describe the cabinet color or sheen. The wood species or material you choose can impact the shade of the color, so we encourage you to order a sample door before finalizing your selections.</p>

        <div v-for="finishType in availableFinishTypes"
            class="finish-types__group">
            <finishes 
                :title="finishType.title"
                :cols="3"
                :finish-type="finishType.id"
                />
        </div>

        <div v-for="finishType in unavailableFinishTypes" class="finish-types__group">
            <header class="finish-types__group">
                <heading :title="finishType.title" />
                <p class="finish-types__copy">{{ finishTypeUnavailableCopy(finishType) }}</p>
            </header>
        </div>
    </article>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

import Heading from '@/components/Heading'
import Finishes from '@/components/options/Finishes'

export default {
    props: {
        title: {type: String, required: false},
        cols: {type: Number, default: 1},
        isLarge: {type: Boolean, default: false }
    },
    components: {
        Heading,
        Finishes
    },
    data: () => ({
        activeAccordion: 0
    }),
    computed: {
        ...mapGetters([
            'isDesktop',
			'hasNewFinishes',
            'isFinishSelected',
			'filteredFinishes',
            'isFinishDisabled',
            'availableFinishes',
            'availableWoods',
            'getWoodsByFinishTypeId'
        ]),
        ...mapState({
            finishes: state => state.finishes.list,
			finishTypes: state => state.finishes.types,
            selected: state => state.finishes.selected,
        }),
        availableFinishTypes() {
            return this.finishTypes.filter(
                x =>  this.availableFinishes.filter(f => f.type_id == x.id).length
            );
        },
        unavailableFinishTypes() {
            return this.finishTypes.filter(
                x =>  this.availableFinishes.filter(f => f.type_id == x.id).length == 0
            );
        }
    },
    methods: { 
        ...mapActions(['selectFinish', 'setFinishesSort']),
        toggleAccordion(index) {
            if (this.activeAccordion == index) {
                this.activeAccordion = null
            } else {
                this.activeAccordion = index
            }
        },
        finishTypeUnavailableCopy(finishType) {
            const woodsForType = this.getWoodsByFinishTypeId(finishType.id);
            // series of supported wood species for finish type
            const supportedWoodSpeciesCopy = (() => {
                // if only one available wood species return the title
                if (woodsForType.length == 1) return woodsForType[0].title;

                // if multiple wood species return a series of the available woods title's
                let copy = "";
                woodsForType.forEach((x, i) => {
                    if ( woodsForType.length == i + 1) copy += " and ";
                    copy += x.title;
                    if ( woodsForType.length > i + 2) copy += ", ";
                });
                return copy;
            })();

            return `${finishType.title} is only available for the ${supportedWoodSpeciesCopy} wood species.`;
        }
    }
}
</script>

<style lang="scss">
.finish-types {
    &__header {
        &--mobile {
            border-bottom-width: 0 !important;
            margin-bottom: .5rem !important;
            .heading__title { text-align: left !important; }
        }
    }
    &__subheading {
        @apply text-green font-semibold uppercase;
        @apply mb-3;
    }
    &__copy {
        max-width: 720px;
        text-align: left;
        @apply mb-6 text-sm;
    }
    &__colors {
        @apply mb-6;
    }
    &__sort {
        &--mobile {
            @apply border-solid border-green;
            border-top-width: 1px;
            border-bottom-width: 1px;
            @apply my-4 py-4;
        }
    }
    &__group {
        &--unavailable .accordion__header {
            @apply flex-col items-start; 
            @apply py-4;

            .accordion__subtitle {
                @apply text-sm;
            }
        }
    }
}
</style>
