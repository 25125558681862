<template>
    <section class="slider">
        <router-link
            class="grid-item__link grid-item__link--image"
            :to="link"
            :data-name="doorName"
            >
            <transition-group tag="div"
                class="slider__item"
                :name="direction > 0 ? 'slide' : 'slide-invert'"
                >
                <img class="slider__img"
                     v-for="(src, index) in slides"
                     :src="src"
                     :key="src"
                     v-show="index === activeIndex"
                     />
            </transition-group>
        </router-link>

        <nav class="slider__nav" v-touch:swipe.left="advance" v-touch:swipe.right="regress">
            <button class="slider__btn slider__btn--back"
                v-if="activeIndex > 0"
                @click="regress()"
                :data-name="doorName"
                >
                <fa-icon class="slider__icon slider__icon--back" :icon="['far', 'angle-left']" />
            </button>
            <button class="slider__btn slider__btn--fwd"
                v-if="activeIndex < (this.slides.length - 1)"
                @click="advance()"
                :data-name="doorName"
                >
                <fa-icon class="slider__icon slider__icon--fwd" :icon="['far', 'angle-right']" />
            </button>
        </nav>

    </section>
</template>

<script>
export default {
    props: {
        slides: {type: Array, required: true},
        link: {required: true},
        doorName: {type: String, required: true}
    },
    data: () => ({
        direction: 1,
        activeIndex: 0,
    }),
    methods: {
        advance() {
            if (this.activeIndex < (this.slides.length - 1)) {
                this.direction = 1
                this.activeIndex++
            }
        },
        regress() {
            if (this.activeIndex > 0) {
                this.direction = -1
                this.activeIndex--
            }
        }
    },
}
</script>

<style lang="scss">
.slider {
    position: relative;

    &__item {
        @apply relative block m-0 p-0;
        @apply w-full h-0;
        padding-top: 200%;
        overflow: hidden;
    }

    &__img {
        @apply absolute top-0 left-0;
        z-index: 0;
    }

    &__nav {
        @apply flex justify-between w-full h-full;
        @apply absolute inset-0;
        z-index: 0;
        pointer-events: none;
    }

    &__btn {
        @apply px-2 cursor-pointer;
        @apply text-white text-3xl;
        background: rgba(0,0,0,0.25);

        @apply block w-12 h-12;
        @apply center-v;
        pointer-events: all;

        svg {
            pointer-events: none;
            @apply center;
        }

        &--back { left: 2px; }
        &--fwd { right: 2px; }

        &:focus { outline: none; }
        &:hover {
            background: rgba(0,0,0,0.5);
        }
    }
}
.slide-enter-active, .slide-leave-active,
.slide-invert-enter-active, .slide-invert-leave-active {
    transition: left 250ms;
}
.slide-enter { left: 100% !important; }
.slide-leave-to { left: -100% !important; }
.slide-invert-enter { left: -100% !important; }
.slide-invert-leave-to { left: 100% !important; }
</style>
