<template>
    <section class="loading">
        <svg version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100px"
            height="100px"
            viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;"
            xml:space="preserve"
            >
            <path fill="#3F4727" d="M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50c27.6,0,50-22.4,50-50C100,22.4,77.6,0,50,0z M78.9,21.1
                        L78.9,21.1c2.1,2.1,2.6,5.5,1.6,9.4c-1,3.6-3.2,7.3-6.2,10.4c-3,3-6.7,5.3-10.4,6.2c-1.3,0.3-2.5,0.5-3.7,0.5
                        c-2.3,0-4.3-0.7-5.7-2.1c-2.6-2.6-2.2-6.4-1.8-8.5c0.8-3.8,3.1-7.9,6.5-11.2c4.2-4.2,9.5-6.8,14-6.8C75.5,19,77.5,19.8,78.9,21.1z
                        M33.4,11.5C33.6,9.4,35,7.8,37,7.2c8.4-2.5,17.6-2.5,26.1,0c2,0.6,3.3,2.2,3.6,4.3c0.2,2.1-0.7,4-2.5,5c-2.2,1.3-4.3,2.9-6.2,4.7
                        c-1.9,1.9-3.5,3.9-4.8,6.1c-0.7,1.1-1.9,1.8-3.2,1.8c-1.3,0-2.5-0.7-3.2-1.8c-1.3-2.2-2.9-4.2-4.8-6.1c-1.9-1.9-4-3.5-6.2-4.7
                        C34.1,15.4,33.1,13.5,33.4,11.5z M21.1,21.1c1.4-1.4,3.3-2.1,5.7-2.1c1.1,0,2.1,0.2,2.8,0.3c3.8,0.8,7.9,3.1,11.2,6.5
                        c3.3,3.3,5.7,7.4,6.5,11.2c0.4,2.1,0.8,5.9-1.8,8.5c-1.6,1.6-3.8,2.1-5.7,2.1c-1.1,0-2-0.1-2.8-0.3c-3.8-0.8-7.9-3.1-11.2-6.5
                        C19.2,34.3,17,25.2,21.1,21.1z M12.1,66.7c-0.2,0-0.4,0-0.6,0c-2-0.2-3.7-1.6-4.3-3.6c-1.3-4.2-1.9-8.6-1.9-13c0-4.4,0.6-8.8,1.9-13
                        c0.6-2,2.2-3.3,4.3-3.6c2.1-0.2,4,0.7,5,2.5c1.3,2.2,2.9,4.3,4.7,6.2c1.8,1.9,3.9,3.5,6.1,4.7c1.1,0.7,1.8,1.9,1.8,3.2
                        c0,1.3-0.7,2.5-1.8,3.2c-2.2,1.3-4.2,2.9-6.1,4.8c-1.9,1.9-3.5,4-4.7,6.2C15.5,65.7,13.9,66.7,12.1,66.7z M21.1,78.9
                        c-4.1-4.1-2-13.1,4.7-19.7c3.3-3.3,7.4-5.7,11.2-6.5c3.5-0.7,6.6-0.1,8.5,1.8c2.6,2.6,2.2,6.4,1.8,8.5c-0.8,3.8-3.1,7.9-6.5,11.2
                        c-4.3,4.3-9.7,6.7-14,6.7C24.5,81,22.5,80.3,21.1,78.9z M66.6,88.5c-0.2,2-1.6,3.7-3.6,4.3c-4.2,1.3-8.6,1.9-13,1.9
                        c-4.4,0-8.8-0.6-13-1.9c-2-0.6-3.3-2.2-3.6-4.3c-0.2-2,0.7-4,2.5-5c2.2-1.3,4.3-2.9,6.2-4.7c1.9-1.9,3.5-3.9,4.7-6.1
                        c0.7-1.1,1.9-1.8,3.2-1.8h0c1.3,0,2.5,0.7,3.2,1.8c1.3,2.2,2.9,4.2,4.8,6.1c1.9,1.9,4,3.5,6.2,4.7C65.9,84.6,66.9,86.5,66.6,88.5z
                        M78.9,78.9c-1.4,1.4-3.4,2.1-5.7,2.1c-1.1,0-2.4-0.2-3.7-0.5c-3.6-1-7.3-3.1-10.4-6.1c-3.3-3.3-5.7-7.3-6.5-11.1
                        c-0.4-2-0.8-5.9,1.8-8.4h0c4-3.9,13.4-1.7,19.7,4.6C80.8,66,83,74.9,78.9,78.9z M88.5,66.1c-0.2,0-0.4,0-0.6,0
                        c-1.8,0-3.4-0.9-4.4-2.5c-1.3-2.2-2.9-4.2-4.7-6.1c-1.9-1.8-3.9-3.4-6-4.7c-1.1-0.7-1.8-1.8-1.8-3.1c0-1.3,0.7-2.5,1.8-3.2
                        c2.2-1.3,4.2-2.8,6-4.7c1.9-1.9,3.5-3.9,4.7-6.1c1-1.8,2.9-2.7,5-2.5c2.1,0.2,3.7,1.6,4.3,3.5c1.3,4.2,1.9,8.5,1.9,12.8
                        c0,4.4-0.6,8.7-1.9,12.8C92.2,64.5,90.6,65.9,88.5,66.1z"
                        />
        </svg>
        <p>Loading Assets &ndash; Please wait...</p>
    </section>
</template>

<script>
export default {}
</script>

<style lang="scss">
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.loading {
    @apply bg-white p-16 text-center;
    @apply flex flex-col justify-center items-center;
    svg {
        @apply mb-8;
        animation: spin 3s infinite ease-in-out;
    }
}
</style>
