export const filterStateBySelected = function(state) {
  if (!state.selected) {
    return null
  }

  return state.list.filter(x => x.id == state.selected)[0]
}

export const selectedFromUrl = function(rootState, state, key) {
  if (!rootState.route.params[key]) {
    return null
  }

  return state.list.filter(x => x.id == rootState.route.params[key])
}

export const buildHttpQuery = function(data) {
  if (typeof data == 'string') return data

  let q = []

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      q.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    }
  }

  return q.join('&')
}

export const buildUrlWithQuery = function(url, data) {
  const q = buildHttpQuery(data)

  return `${url}?${q}`
}
