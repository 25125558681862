import Vue from 'vue'

import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
    faCircle
} from '@fortawesome/pro-solid-svg-icons'
import {
    faTimes,
    faArrowToBottom
} from '@fortawesome/pro-light-svg-icons'
import {
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faCheck,
    faMapMarkerAlt,
    faSlidersH,
    faSortAmountDown,
    faEnvelope,
    faBookmark,
    faPrint,
    faClipboard,
	faSync,
	faPlayCircle,
    faTimes as farTimes,
    faExclamationCircle,
    faInfoCircle
} from '@fortawesome/pro-regular-svg-icons'
import {
    faHouzz,
    faPinterest
} from '@fortawesome/free-brands-svg-icons'

library.add(
    // solid
    faCircle,
    // regular
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faCheck,
    faMapMarkerAlt,
    faSlidersH,
    faSortAmountDown,
    faBookmark,
    faEnvelope,
    faPrint,
    faClipboard,
	faSync,
	faPlayCircle,
    // light
    faTimes,
	farTimes,
    faArrowToBottom,
    faExclamationCircle,
    faInfoCircle,
    // brand
    faHouzz,
    faPinterest
)

Vue.component('fa-icon', FontAwesomeIcon)
