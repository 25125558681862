<template>
    <section class="container">
        <transition-group name="list" mode="out-in" class="grid">
            <grid-item
                v-for="door in availableDoors"
                :key="door.id"
                :door="door"
                />
        </transition-group>

        <disclaimer class="grid__disclaimer"/>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'

import GridItem from './Item'
import Disclaimer from '@/components/Disclaimer'

export default {
    components: {
        GridItem,
        Disclaimer
    },
    computed: mapGetters(['availableDoors']),
}
</script>

<style scoped lang="scss">
.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: theme('spacing.12');

    @screen sm {
        grid-template-columns: repeat(3, 1fr);
    }

    @screen md {
        grid-template-columns: repeat(4, 1fr);
    }

    @screen lg {
        grid-template-columns: repeat(5, 1fr);
    }
    @screen xl {
        grid-template-columns: repeat(6, 1fr);
    }

    &__disclaimer {
        @apply mt-10;
    }
}
.list-enter-active, .list-leave-active {
  transition: all 250ms;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
