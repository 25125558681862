import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from './modules/app'
import api from './modules/api'

import doors from './modules/doors'
import variants from './modules/variants'

import styles from './modules/styles'
import woods from './modules/woods'
import finishes from './modules/finishes'
import glazes from './modules/glazes'
import drawers from './modules/drawers'

export default new Vuex.Store({
    modules: {
        app,
        api,
        doors,
        variants,
        styles,
        woods,
        finishes,
        glazes,
        drawers
    }
})
