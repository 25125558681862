<template>
    <div class="list" :class="`list--col-${cols}`">
        <slot />
    </div>
</template>

<script>
import CircleTag from './CircleTag'

export default {
    props: {
        cols: {type: Number, default: 1}
    },
    components: {
        CircleTag
    },
}
</script>

<style scoped lang="scss">
.list {
    display: grid;

    @screen md {
        column-gap: theme('spacing.4') ;

        &--col-1 { grid-template-columns: repeat(1, 1fr); }
        &--col-2 { grid-template-columns: repeat(2, 1fr); }
        &--col-3 { grid-template-columns: repeat(3, 1fr); }
    }

    &__item {}
}
</style>
