<template>
    <span
        class="pill"
        :class="{
                'pill--interactive': this.isInteractive,
                'pill--ghost': this.isGhost,
                'pill--large': this.isLarge,
                'pill--alert': this.isAlert,
                'pill--info': this.isInfo
                }"
        @click.prevent="handleClick"
        >
        <slot />
        <span class="pill__action" v-if="isInteractive">
            <fa-icon :icon="['fal', 'times']" />
        </span>
    </span>
</template>

<script>
export default {
    props: {
        icon: { type: [String, Array], required: false },
        isInteractive: { type: Boolean, default: false },
        handler: { type: Function, required: false },
        isGhost: { type: Boolean, default: false },
        isLarge: { type: Boolean, default: false },
        isAlert: { type: Boolean, default: false },
        isInfo: { type: Boolean, default: false },
    },
    methods: {
        handleClick() {
            return !this.isInteractive || this.$emit('click')
        }
    }
}
</script>

<style lang="scss">
.pill {
    @apply inline-flex relative items-center;
    @apply py-0 px-4 rounded-full;
    @apply bg-clay-dark text-white;
    @apply text-xs font-sans leading-loose uppercase;
    transition: all ease 250ms;

    & > * { pointer-events: none; }

    &--interactive {
        cursor: pointer;
        &:hover { @apply bg-clay; }
    }

    &--large {
        @apply text-base text-center py-2 px-6;
        @apply block w-full mb-4;
    }

    &--ghost {
        @apply bg-transparent text-green;
        @apply border border-green;

        &:hover {
            @apply bg-green text-white;
        }
    }
    &--alert {
      @apply bg-redbud cursor-pointer py-1;
      line-height: 1.334;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      svg {
        font-size: 1.2em;
      }
    }
    &--info {
      @apply cursor-pointer py-1;
      line-height: 1.334;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      svg {
        font-size: 1.2em;
      }
    }

    &__action {
        @apply ml-2;
    }
}
</style>
