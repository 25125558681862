<template>
    <form class="sortform" :class="{'sortform--lrg': isLarge}">
        <span class="sortform__title" :class="{'sortform__title--lrg': isLarge}"v-show="isDesktop">Sort By:</span>
        <div
            v-for="option in options"
            class="sortform__option radio"
            :class="{'radio--checked': option.id === selected, 'radio--lrg': isLarge}"
            @click.prevent="$emit('click', option.id)"
            >
            <span class="radio__circle" :class="{'radio__circle--lrg': isLarge}"></span>
            <span class="radio__label" :class="{'radio__label--lrg': isLarge}">{{ option.label }}</span>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        prefix: {type: String, required: true},
        options: {type: Array, required: true},
        selected: {type: String, required: true},
        isLarge: {type: Boolean, defult: false}
    },
    computed: mapGetters(['isDesktop']),
    methods: {
        getId(suffix = null) {
            return suffix
                ? `${this.prefix}-sortby-${suffix}`
                : `${this.prefix}-sortby`;
        }
    },
}
</script>

<style scoped lang="scss">
.sortform {
    min-width: 200px;

    @apply flex items-center;

    &--lrg {
        @apply flex-col;
    }
}
.radio {
    @apply flex items-center w-auto;
    cursor: pointer;

    &--lrg {
        @apply w-full bg-oatmeal;
        @apply py-3 px-6;
        @apply border-b border-green;
    }

    &--checked {
        .radio__circle::after { transform: translate(-50%, -50%) scale(1); }
    }

    > * { pointer-events: none; }
    &__circle {
        @apply relative block rounded-full;
        @apply bg-white border border-green;
        @apply w-4 h-4;
        @apply ml-3 mr-1;

        &::after {
            content: "";
            @apply block rounded-full bg-green;
            @apply w-2 h-2;
            @apply center;
            transform: translate(-50%,-50%) scale(0);
            transition: all ease 250ms;
            transform-origin: center;
        }

        &--lrg {
            @apply w-8 h-8;
            @apply mr-4;

            &::after {
                @apply w-6 h-6;
            }
        }

    }
    &__label {

        &--lrg {
            @apply text-green text-lg font-bold uppercase;

        }
    }
}
</style>
