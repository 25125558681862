<template>
    <article class="finish-types">
        <heading
            v-if="!!title"
            class="finish-types__header"
            :title="title"
            >
        </heading>
        <p class="finish-types__copy">We use the term finish to describe the cabinet color or sheen. The wood species or material you choose can impact the shade of the color, so we encourage you to order a sample door before finalizing your selections.</p>

        <div id="finish-types-list">
            <finish-type 
                v-for="finishType in selectedDoorFinishTypes"
                class="finish-types__group"
                :title="finishType.title"
                :key="finishType.id"
                :id="finishType.id"
                :is-open="selectedFinish.type_id == finishType.id"
                @toggle="toggleFinishType(finishType)"
                >

                <div v-if="finishType.description"
                class="finish-types__copy"
                v-html="finishType.description"
                />

                <h4 class="finish-types__subheading">Available Colors</h4>
                <finishes 
                    :cols="3"
                    :finish-type="finishType.id"
                    />

                <div v-if="getWoodsByFinishTypeId(finishType.id).length > 1">
                    <h4 class="finish-types__subheading">Available Wood Species</h4>
                    <woods class="selected__section selected__section--woods"
                        :cols="3"
                        :finish-type="finishType.id"
                        />
                </div>
            </finish-type >
        </div>

        <div v-for="finishType in unavailableFinishTypes" class="finish-types__group finish-types__group--unavailable accordion accordion--default">
            <header class="accordion__header">
                <h3 class="accordion__title">{{ finishType.title }}</h3>
                <p class="accordion__subtitle">{{ finishType.title }} is unavailable for this door style.</p>
            </header>
        </div>
    </article>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

import Heading from '@/components/Heading'
import FinishType from './FinishType'
import Woods from '@/components/options/Woods'
import Finishes from '@/components/options/Finishes'

export default {
    props: {
        title: {type: String, required: false},
        cols: {type: Number, default: 1},
        isLarge: {type: Boolean, default: false }
    },
    components: {
        Heading,
        Finishes,
        FinishType,
        Woods
    },
    computed: {
        ...mapGetters([
            'availableFinishes',
            'availableWoods',
            'initialVariant',
            'filteredFinishes',
            'getWoodsByFinishTypeId',
            'hasNewFinishes',
            'isDesktop',
            'isFinishSelected',
            'isFinishDisabled',
            'selectedDoor',
            'selectedDoorFinishTypes',
            'selectedDoorWoods',
            'selectedDoorFinishes',
            'selectedDoorVariants',
            'selectedFinish'
        ]),
        ...mapState({
            finishes: state => state.finishes.list,
			finishTypes: state => state.finishes.types,
            selected: state => state.finishes.selected,
        }),
        unavailableFinishTypes() {
            return this.finishTypes.filter(x => !this.selectedDoorFinishTypes.includes(x));
        }
    },
    methods: { 
        ...mapActions(['selectFinish', 'setFinishesSort', 'selectWood']),
        toggleFinishType(finishType) {
            // proceed if finish type triggered is not already expanded
            if (finishType.id != this.selectedFinish.type_id) {
                // if on mobile scroll to tope of list
                if ( window.innerWidth < 1024) window.scrollTo(0, document.getElementById('finish-types-list').offsetTop);

                /**
                 * When the user changes filter type we need to swap out the selected finish & wood.
                 * This is needed because otherwise the selected finish / wood of the previously expanded filter type
                 * would make all the finishes / woods of the current filter type disabled.
                 * 
                 * When a filter type is expanded, we find a variant of this filter type by using one of three hierarchal 
                 * conditions. Once one of those conditions is satisfied, we use that variant and break.
                 */

                // 1) Is the door's inital value in this finish type?
                if ( this.initialVariant.finish == finishType.id ) {
                    this.selectFinish(this.initialVariant.finish);
                    this.selectWood(this.initialVariant.wood);
                    return;
                }

                // 2) Is the Finish type's default finish & wood a variant of this door?
                const finishTypeinitialVariant = this.selectedDoorVariants
                    .find(x => x.finish_id == finishType.default_finish_id && x.wood_id == finishType.default_wood_id);
                if ( finishTypeinitialVariant ) {
                    this.selectFinish(finishType.default_finish_id);
                    this.selectWood(finishType.default_wood_id);
                    return;
                } 

                // 3) If all other methods fail, select first variant w/ finish & wood of this type
                const finishIdsOfType = this.selectedDoorFinishes
                    .filter(x => x.type_id == finishType.id)
                    .map(x => x.id);
                const woodIdsOfType = this.selectedDoorWoods
                    .filter(x => x.finish_types.filter(ft => ft.pivot.finish_type_id == finishType.id).length)
                    .map(x => x.id);
                const firstMatchingVariant = this.selectedDoorVariants
                    .find(x => finishIdsOfType.indexOf(x.finish_id) && woodIdsOfType.indexOf(x.wood_id));
                
                this.selectFinish(firstMatchingVariant.finish_id);
                this.selectWood(firstMatchingVariant.wood_id);
                
            } 
        }
    }
}
</script>

<style lang="scss">
.finish-types {
    &__header {
        &--mobile {
            border-bottom-width: 0 !important;
            margin-bottom: .5rem !important;
            .heading__title { text-align: left !important; }
        }
    }
    &__subheading {
        @apply text-green font-semibold uppercase;
        @apply mb-3;
    }
    &__copy {
        max-width: 720px;
        text-align: left;
        @apply mb-6 text-sm;
    }
    &__colors {
        @apply mb-6;
    }
    &__sort {
        &--mobile {
            @apply border-solid border-green;
            border-top-width: 1px;
            border-bottom-width: 1px;
            @apply my-4 py-4;
        }
    }
    &__group {
        &--unavailable .accordion__header {
            @apply flex-col items-start; 
            @apply py-4;

            .accordion__subtitle {
                @apply text-sm;
            }
        }
    }
}
</style>
